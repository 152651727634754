import React from "react";
import GiftCard from "./GiftCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

const CardFrontOption = ({ label, img, onClick }) => {
  const classes = useStyles();

  return (
    <div className={"card-front-option"} onClick={onClick}>
      <div className={"card-front-content-container"}>
        <div className={"img-container"}>
          <div className={"border"}>
            <img src={img} />
          </div>
        </div>
        <p>{label}</p>
      </div>
    </div>
  );
};

export default CardFrontOption;
