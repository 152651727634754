import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  withStyles,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Base from "./../../component/Layout/Base";
import { fetchLocations, deleteLocation } from "../../redux/requests/locationRequests";
import { TOAST } from "../../redux/actions/actions-types";
import { useHistory } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  backwrapper: {
    // backgroundColor: "#000",
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 1rem",
    position: "sticky",
    left: "0",
    top: "0",
  },
  totalCardAmount: {
    fontFamily: "Lato",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "29px",
    textAlign: "left",
    padding: "70px 15px",
  },
});

const LocationList = () => {
  const classes = useStyles();
  const [locationList, setLocationList] = useState([]);
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  // Fetch location list
  useEffect(() => {
    getLocations();
    return;
  }, []);

  const getLocations = async () => {
    setLoader(true);
    const res = await fetchLocations();
    if (res) {
      setLocationList(res.data);
      setLoader(false);
    }
  };

  const deleteLocationWithId = async (id) => {
    const location = await deleteLocation(id);
    if (location) {
      getLocations();
      dispatch({
        type: TOAST,
        load: {
          type: "success",
          title: "Success",
          message: "Location Deleted",
          show: true,
        },
      });
    }
  };

  return (
    <Base theme={"light"}>
      {loader ? (
        <h1>Loading...</h1>
      ) : (
        <div className="card-container campaign-list-area">
          <div className={classes.backwrapper}>
            <button
              className={`go-back-button active accept-reject-button`}
              onClick={() => {
                history.push("/locations/add");
              }}>
              Add+
            </button>
          </div>
          <TableContainer className="rounded-0">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.tableheadcell}>#</StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>City</StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>Country</StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>Views per roll</StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>Max price per box</StyledTableCell>
                  <StyledTableCell className={classes.tableheadcell}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(locationList) &&
                  locationList.map(({ id, city, country, viewsPerRoll, maxPrizePerBox }, index) => (
                    <StyledTableRow key={id} className={"table-row-min-height"}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{city}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{country}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{viewsPerRoll}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{maxPrizePerBox}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        <button
                          className={`go-back-button active accept-reject-button`}
                          onClick={() => {
                            history.push(`/locations/edit/${id}`);
                          }}>
                          Edit
                        </button>
                        <button
                          className={`go-back-button active accept-reject-button`}
                          onClick={() => deleteLocationWithId(id)}
                          style={{ color: "red", marginLeft: "10px" }}>
                          Delete
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Base>
  );
};

export default LocationList;
