import * as types from '../actions/actions-types'

//saga
export const callUserCards = () => ({
    type: types.CALL_USER_CARDS
})

export const editUserCard = (editCard) => ({
    type: types.EDIT_USER_CARD,
    editCard
})

//reducer
export const addCampaignsCards = (userCards) => {
    return {
        type: types.ADD_CAMPAIGNS_CARDS,
        userCards
    }
}
