import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  makeStyles,
  ListItem,
  Drawer,
  List,
  Divider,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const drawerWidth = 325;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  listIcon: {
    color: "#fff",
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: "#1c1524",
    color: "#fff",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(0),
      left: theme.spacing(-32),
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  invisible: {
    display: "none",
  },
  visible: {
    display: "flex",
  },
}));

function Sidebar(props) {
  const admin = localStorage.getItem("admin");
  const classes = useStyles();
  const role = useSelector((state) => state.login.userData.role);
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx([
          classes.drawerPaper,
          "sidebar-menu-con",
          props.showMenu && "show",
        ]),
      }}
    >
      <List className="drawer-menu-list">
        <ListItem button>
          <NavLink to="/dashboard" activeClassName="active">
            <ListItemText primary="Dashboard" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/gift-card" activeClassName="active">
            <ListItemText primary="Create offer" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/drop-card/standard-gift" activeClassName="active">
            <ListItemText primary="Create new Campaign" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/campaigns" activeClassName="active">
            <ListItemText primary="Existing Campaigns" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/approvals" activeClassName="active">
            <ListItemText primary="My offers" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/invoices" activeClassName="active">
            <ListItemText primary="Invoices" />
          </NavLink>
        </ListItem>
        {role === "admin" && (
          <>
            <ListItem button>
              <NavLink to="/locations" activeClassName="active">
                <ListItemText primary="Locations" />
              </NavLink>
            </ListItem>
            <ListItem button>
              <NavLink to="/tiers" activeClassName="active">
                <ListItemText primary="Tiers" />
              </NavLink>
            </ListItem>
          </>
        )}
        <ListItem button>
          <NavLink to="/profile-settings" activeClassName="active">
            <ListItemText primary="Profile settings" />
          </NavLink>
        </ListItem>

        <ListItem button>
          <NavLink to="/logout" activeClassName="active">
            <ListItemText primary="Logout" />
          </NavLink>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default Sidebar;
