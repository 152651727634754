import React from "react";

const ActionButton = ({ children, onClick, noLeftGap }) => {
  return (
    <button
      className={`transparent-button right-edit-button ${
        noLeftGap && "action-button-no-left-gap"
      }`}
      onClick={onClick}
    >
      <h3>{children}</h3>
    </button>
  );
};

export default ActionButton;
