import React, { Component } from 'react';
import { Drawer, Button } from "@material-ui/core";
import Base from "../../../component/Layout/Map-Base";
import CloseIcon from '@material-ui/icons/Close';

import PeopleRatio from "./DrawerComponent/PeopleRatio";
import CreateGoldCard from "./DrawerComponent/CreateGoldCard";
import ConfirmGoldCard from "./DrawerComponent/ConfirmGoldCard";
import PreviewGoldCard from "./DrawerComponent/PreviewGoldCard";


export default class GoldGift extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
            shelters: [],
            right: false,
            selectedMarker: false,
        }
    }
    componentDidMount() {
        fetch("https://api.harveyneeds.org/api/v1/shelters?limit=20")
            .then(r => r.json())
            .then(data => {
                this.setState({ shelters: data.shelters })
            })
    }
    handleClick = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ ...this.state, [side]: open });
    }

    changeTab = (index) => {
        this.setState({ tabIndex: index })
    }
    getTabClass(index) {
        return (index === this.state.tabIndex) ? "view-show" : "view-hide";
    }

    render() {
        return (
            // <Base>
            //     <div className="gold-map-view">
            //         <MapWithAMarker
            //             selectedMarker={this.state.selectedMarker}
            //             markers={this.state.shelters}
            //             onClick={this.handleClick('right', true)}
            //             googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            //             loadingElement={<div style={{ height: `100%` }} />}
            //             containerElement={<div style={{ height: `100%` }} />}
            //             mapElement={<div style={{ height: `100%` }} />}
            //         />
            //         <div className="search-area">
            //             <form name="form-layout" className="addCardForm" noValidate>
            //                 <div className="custom-field custom-input">
            //                     <input type="text" placeholder="Search a Location" />
            //                 </div>
            //             </form>
            //         </div>
            //     </div>
            //     <Drawer className="gold-right-drawer" anchor="right" open={this.state.right}>
            //         <Button className="close" onClick={this.handleClick('right', false)}><CloseIcon /></Button>

            //         <div className={this.getTabClass(0)}>
            //             <PeopleRatio createGoldCard={() => this.changeTab(1)} />
            //         </div>

            //         <div className={this.getTabClass(1)}>
            //             <CreateGoldCard confirmGoldCard={() => this.changeTab(2)} />
            //         </div>

            //         <div className={this.getTabClass(2)}>
            //             <ConfirmGoldCard previewGoldCard={() => this.changeTab(3)} backGoldCard={() => this.changeTab(1)} />
            //         </div>

            //         <div className={this.getTabClass(3)}>
            //             <PreviewGoldCard />
            //         </div>
            //     </Drawer>
            // </Base>
            <div></div>
        );
    }
}