import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormControl from "@material-ui/core/FormControl";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "../../../component/Layout/DateRangePicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { connect, useSelector } from "react-redux";
import { callUserCards } from "../../../redux/actions/campaignsActions";
import Navigator from "../../GiftCard/Navigator";
import GiftCard from "../../GiftCard/DesignOffer/GiftCard";
import Card from "../../../component/Layout/Card";
import ActionButton from "./ActionButton";
import history from "../../../helpers/history";
import {
  fetchCampaign,
  updateCampaignDetails,
  addCategoryCampaign,
  removeCampaignCategory,
} from "../../../redux/requests/cardRequests";
import { addCategory, fetchCategory, updateCategory } from "../../../redux/requests/categoryRequests";
import Base from "../../../component/Layout/Base";
import moment from "moment";
import Switch from "../../../component/Layout/SwitchInput";
import Collapsible from "../../../component/Layout/Collapsible";
import { TOAST } from "../../../redux/actions/actions-types";
import { useDispatch } from "react-redux";
function CamaignsDetail(props) {
  const { userCards } = props;
  const dispatch = useDispatch();

  const [cardSide, setCardSide] = useState("front");
  const [cardIndex, setCardIndex] = useState(0);
  const [editPick, setEditPick] = useState(false);
  const [editShop, setEditShop] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [typedCategory, setTypedCategory] = useState();
  const [editCategory, setEditCategory] = useState();
  const [changedCategory, setChangedCategory] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [contestDateRange, setContestDateRange] = useState();
  const [isContest, setIsContest] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [apGpData, setApGpData] = useState({
    ap: false,
    gp: true,
  });
  const user = useSelector((state) => state.login.userData);
  const isAdmin = user.role === "admin";

  const today = new Date();
  today.setDate(today.getDate() + 1);
  const toggleDatePicker = () => setShowDatePicker(!showDatePicker);

  useEffect(() => {
    getCampaignData();

    // console.log("isAdmin", user.role);
    // getCategory();
  }, []);

  useEffect(() => {
    if (isAdmin) getCategory();
  }, [isAdmin]);

  const [cards, setCards] = useState([]);

  const getCampaignData = async () => {
    setShowLoading(true);
    let campaignResp = await fetchCampaign();
    if (campaignResp && campaignResp.data) {
      let expiredCards = campaignResp.data.filter((items) => {
        return moment(items.expiresOn).isBefore(moment());
      });
      let notExpiredCards = campaignResp.data.filter((items) => moment(items.expiresOn).isSameOrAfter(moment()));

      setCards([...notExpiredCards, ...expiredCards]);

      const campaign_id = campaignResp.data[cardIndex] && campaignResp.data[cardIndex].id;
      props.setCurrentCampaign(campaign_id);
      setApGp([...notExpiredCards, ...expiredCards][cardIndex]);
      setIsFeaturedAndContest([...notExpiredCards, ...expiredCards][cardIndex]);
      setShowLoading(false);
    }
  };
  const setIsFeaturedAndContest = (card) => {
    if (!card) return;
    setIsContest(card.isContest);
    setIsFeatured(card.isFeatured);
    if (card.contestOn && card.contestDrawOn)
      setContestDateRange({ startDate: new Date(card.contestOn), endDate: new Date(card.contestDrawOn) });
  };
  const getCategory = async () => {
    // if (!isAdmin) return;
    let categoryResp = await fetchCategory();
    if (categoryResp && categoryResp.name !== "Error") {
      setCategories(categoryResp.data);
    }
  };

  const getOptions = () => {
    const card = cards[cardIndex];
    const copyCard = { ...card };
    if (!categories) return [];
    if (!copyCard) return [];
    if (!copyCard.campaignCategories) return categories;
    let data = categories.filter((c) => copyCard.campaignCategories.findIndex((c2) => c2.categoryId === c.id) === -1);
    return data;
  };

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  const setApGp = (card) => {
    if (!card) return;
    let data = { ...apGpData };
    data.ap = card.shopPriceAP && card.shopPriceAP > 0;
    data.gp = card.shopPriceGP && card.shopPriceGP > 0;
    data.apValue = card.shopPriceAP;
    data.gpValue = card.shopPriceGP;
    data.inShop = card.inShop;
    data.inShuffle = card.inShuffle;
    if (!data.gp && !data.ap) {
      data.gp = true;
    }
    setApGpData(data);
  };

  const changeApGP = (key, value) => {
    let data = { ...apGpData };
    if (key === "ap") {
      data.ap = value;
      if (value) {
        data.gp = false;
        data.gpValue = "";
      } else {
        data.gp = true;
      }
    } else if (key === "gp") {
      data.gp = value;
      if (value) {
        data.ap = false;
        data.apValue = "";
      } else {
        data.ap = true;
      }
    } else {
      data[key] = value;
    }
    setApGpData(data);
  };

  const saveApGp = async () => {
    let data = { ...apGpData };
    const card = cards[cardIndex];
    const copyCard = { ...card };
    if (!data.gp && !data.ap) {
      showToast("error", "Error", "One of gold and ap value should be given");
    } else if (data.gp && (!data.gpValue || isNaN(parseInt(data.gpValue)) || data.gpValue < 0)) {
      showToast("error", "Error", "Invalid Gold value");
    } else if (data.ap && (!data.apValue || isNaN(parseInt(data.apValue)) || data.apValue < 0)) {
      showToast("error", "Error", "Invalid ap value");
    } else {
      let submitData = {
        shopPriceAP: data.ap ? parseInt(data.apValue) : 0,
        shopPriceGP: data.gp ? parseInt(data.gpValue) : 0,
        inShop: data.inShop,
        inShuffle: data.inShuffle,
      };
      let updateResp = await updateCampaignDetails(copyCard.id, submitData);
      getCampaignData();
      setEditShop(false);
    }
  };

  const changeCategory = (value) => {
    if (selectedCategory) setSelectedCategory();
    setTypedCategory(value);
  };

  const saveCategory = async () => {
    const card = cards[cardIndex];
    const copyCard = { ...card };

    if (selectedCategory) {
      if (copyCard.campaignCategories && copyCard.campaignCategories.length > 0) {
        if (copyCard.campaignCategories.findIndex((items) => items.categoryId === selectedCategory) > -1) {
          return showToast("error", "Error", "This category already added.");
        }
      }
      let campaignCategoryResp = await addCategoryCampaign(copyCard.id, selectedCategory);
      // getCampaignData();
      updateCardForCategory(campaignCategoryResp);

      showToast("success", "Done", "New category added.");
      setTypedCategory("");
    } else if (typedCategory && typedCategory.length > 0) {
      let isCategoryExists = categories.filter((items) => items.name.toLowerCase() === typedCategory.toLowerCase());
      if (isCategoryExists && isCategoryExists.length > 0) {
        if (copyCard.campaignCategories && copyCard.campaignCategories.length > 0) {
          if (copyCard.campaignCategories.findIndex((items) => items.categoryId === isCategoryExists[0].id) > -1) {
            return showToast("error", "Error", "This category already added.");
          }
        }
        let campaignCategoryResp = await addCategoryCampaign(copyCard.id, isCategoryExists[0].id);
        // getCampaignData();
        updateCardForCategory(campaignCategoryResp);
        showToast("success", "Done", "New category added.");
        setTypedCategory("");
      } else {
        let categoryResp = await addCategory({ name: typedCategory });
        if (categoryResp && categoryResp.name !== "Error") {
          showToast("success", "Done", "New category added.");
          let category_data = [...categories];
          category_data.push(categoryResp.data);
          setCategories(category_data);
          let campaignCategoryResp = await addCategoryCampaign(copyCard.id, categoryResp.data.id);

          if (campaignCategoryResp && campaignCategoryResp.name !== "Error") {
            showToast("success", "Done", "Category added to campaign.");
            updateCardForCategory(campaignCategoryResp, category_data);

            // getCampaignData();
          }
        }
      }
    } else {
      return showToast("error", "Error", "Category couldn't be added ");
    }
  };

  const updateCardForCategory = (categoryResp, category_data = null) => {
    let allCategory = [...categories];
    if (category_data) {
      allCategory = category_data;
    }

    const card = cards[cardIndex];
    let copyCard = { ...card };
    if (copyCard.campaignCategories && copyCard.campaignCategories.length > 0) {
      categoryResp.data.category = allCategory.filter((items) => items.id === categoryResp.data.categoryId)[0];
      console.log("categoryResp :>> ", categoryResp);
      copyCard.campaignCategories.push(categoryResp.data);
    } else {
      copyCard.campaignCategories = [];
      categoryResp.data.category = allCategory.filter((items) => items.id === categoryResp.data.categoryId)[0];
      copyCard.campaignCategories.push(categoryResp.data);
    }
    updateCard(copyCard);
  };

  const updateCampaignCategory = async (categoryId) => {
    let categoryResp = await updateCategory(categoryId, { name: changedCategory });
    if (categoryResp && categoryResp.name !== "Error") {
      const card = cards[cardIndex];
      let copyCard = { ...card };
      let campaignCategories = [...copyCard.campaignCategories];
      let editCatIndex = campaignCategories.findIndex((items) => items.categoryId === categoryId);
      campaignCategories[editCatIndex].category.name = changedCategory;
      copyCard.campaignCategories = campaignCategories;
      updateCard(copyCard);

      showToast("success", "Done", "Category updated");
      getCategory();
      setChangedCategory();
      setEditCategory();
    }
  };
  const removeCategory = async (categoryId) => {
    const card = cards[cardIndex];
    const copyCard = { ...card };
    let campaignResp = await removeCampaignCategory(copyCard.id, categoryId);
    if (campaignResp && campaignResp.name !== "Error") {
      showToast("success", "Done", "Category deleted");
      copyCard.campaignCategories = copyCard.campaignCategories.filter((items) => items.categoryId !== categoryId);
      updateCard(copyCard);
    }
  };

  const onChangeDatesRange = (range) => {
    setContestDateRange(range);
  };

  const saveContest = async () => {
    const card = cards[cardIndex];
    let copyCard = { ...card };
    if (isContest && !contestDateRange) {
      showToast("error", "Error", "Contest dates must have to be selected.");
    } else if (isContest) {
      let data = {
        contestOn: moment.utc(contestDateRange.startDate).format(),
        contestDrawOn: moment.utc(contestDateRange.endDate).format(),
        isContest,
      };
      let campaignResp = await updateCampaignDetails(copyCard.id, data);
      if (campaignResp && campaignResp.name !== "Error") {
        showToast("success", "Done", "Campaign updated");
        copyCard = { ...copyCard, ...data };
        updateCard(copyCard);
      }
    } else {
      let data = {
        isContest: false,
      };
      let campaignResp = await updateCampaignDetails(copyCard.id, data);
      if (campaignResp && campaignResp.name !== "Error") {
        showToast("success", "Done", "Campaign updated");
        copyCard = { ...copyCard, ...data };
        updateCard(copyCard);
      }
    }
  };

  const saveFeatured = async () => {
    const card = cards[cardIndex];
    let copyCard = { ...card };
    let data = {
      isFeatured,
    };
    let campaignResp = await updateCampaignDetails(copyCard.id, data);
    if (campaignResp && campaignResp.name !== "Error") {
      showToast("success", "Done", "Campaign updated");
      copyCard = { ...copyCard, ...data };
      updateCard(copyCard);
    }
  };

  const renderCard = (card) => {
    return (
      <GiftCard
        {...card.card}
        side={cardSide}
        logoPhoto={
          card.card.logoPhoto && card.card.logoPhoto.includes(window.location.protocol)
            ? card.card.logoPhoto
            : `${process.env.REACT_APP_BASE_URL}/uploads/avatars/${card.card.logoPhoto}`
        }
        view
        viewDetails
        shadow
        rounded
        step={3}
      />
    );
  };

  const handleOnClickBlock = async () => {
    const card = cards[cardIndex];
    const copyCard = { ...card };
    copyCard.status = copyCard.status === "active" ? "paused" : "active";
    updateCard({ status: copyCard.status });

    let updateResp = await updateCampaignDetails(copyCard.id, {
      status: copyCard.status,
    });
  };

  const updateCard = (cardObj) => {
    let card = { ...cards[cardIndex] };
    const newCard = { ...card, ...cardObj };
    const cardsCopy = [...cards];
    cardsCopy[cardIndex] = newCard;
    setCards(cardsCopy);
  };

  const handleOnNavigateToNext = () => {
    const lengthOfCards = cards.length;

    if (lengthOfCards - 1 > cardIndex) {
      setCardIndex(cardIndex + 1);
      const campaign_id = cards[cardIndex + 1].id;
      setApGp(cards[cardIndex + 1]);
      setIsFeaturedAndContest(cards[cardIndex + 1]);
      props.setCurrentCampaign(campaign_id);
    }
  };

  const handleOnNavigateToPrev = () => {
    const lengthOfCards = cards.length;

    if (cardIndex - 1 >= 0) {
      setCardIndex(cardIndex - 1);
      const campaign_id = cards[cardIndex - 1].id;
      setApGp(cards[cardIndex - 1]);
      setIsFeaturedAndContest(cards[cardIndex - 1]);

      props.setCurrentCampaign(campaign_id);
    }
  };

  const switchCardSide = () => {
    // const card = cards[cardIndex];
    // updateCard({ side: card.side === "front" ? "back" : "front" });
    setCardSide(cardSide === "front" ? "back" : "front");
  };

  const handleOnClickEditCardDetails = () => {
    history.push(`/campaigns/edit-offer-details/${cards[cardIndex].cardId}`);
  };

  const handleOnClickEditTargetting = () => {
    history.push(`/drop-card/standard-gift?edit=true&id=${cards[cardIndex].id}`);
  };
  const getExpiry = (date) => {
    let dateDiff = moment(date).diff(moment());
    return dateDiff < 0 ? (
      <>
        <h3>Expired:</h3>
        <p>{moment(date).format("LLL")}</p>
      </>
    ) : (
      <>
        <h3>Expires on:</h3>
        <p>{moment(date).format("LLL")}</p>
      </>
    );
  };

  const getCategoryName = (categoryId) => {
    let allCategories = [...categories];
    let selectedCategory = allCategories.findIndex((items) => items.id === categoryId);
    selectedCategory = allCategories[selectedCategory];

    return selectedCategory.name;
  };

  const renderCampaignCategories = (campaignCategory) => {
    let items = [];
    items =
      categories &&
      campaignCategory.map((item) => {
        const categoryName = getCategoryName(item.categoryId);
        return (
          <Grid container spacing={3} key={item.id}>
            <Grid item xs={8}>
              <div className="category-items">
                {item.id === editCategory ? (
                  <input
                    value={changedCategory || categoryName}
                    onChange={(e) => setChangedCategory(e.target.value)}
                    className="switch-input"
                  />
                ) : (
                  categoryName
                )}
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                {item.id === editCategory ? (
                  <Button
                    onClick={() => {
                      updateCampaignCategory(item.categoryId);
                    }}>
                    save
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setChangedCategory(categoryName);
                      setEditCategory(item.id);
                    }}>
                    Edit
                  </Button>
                )}
                <Button onClick={() => removeCategory(item.categoryId)}>Delete</Button>
              </div>
            </Grid>
          </Grid>
        );
      });
    return items;
  };

  if (!showLoading) {
    if (cards.length) {
      let card = cards[cardIndex];
      if (card.card.cardPhoto && !card.card.cardPhoto.includes(window.location.protocol)) {
        card.card.cardPhoto = `${process.env.REACT_APP_BASE_URL}/uploads/card-photo/${card.card.cardPhoto}`;
      }

      return (
        <div className={"middle-content-area"}>
          <div className={"middle-content-area transparent create-new-campaign"}>
            <div className={"create-campaign-card-con"}>
              {renderCard(card)}
              <div className={"text-center"}>
                <button className={"transparent-btn view-details-btn"} onClick={switchCardSide}>
                  Tap to view details
                </button>
              </div>
            </div>

            <Navigator onNavigateToPrev={handleOnNavigateToPrev} onNavigateToNext={handleOnNavigateToNext} />

            <div className={"existing-campaign-edit-section-1"}>
              <div className={"edit-status-container"}>
                <Card>
                  <div className={"campaign-edit-con"}>
                    <h3>Status: </h3>&nbsp;
                    <p style={{ textTransform: "capitalize" }}>{card.status.split("-").join(" ")}</p>
                    <div>
                      <ActionButton onClick={handleOnClickBlock} noLeftGap>
                        {card.status.toLowerCase() !== "active" ? "Start campaign" : "Pause campaign"}
                      </ActionButton>
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"edit-sec-1-divider"}></div>
              <div className={"right-container"}>
                <Card>
                  <div className={"campaign-edit-con-right"}>
                    <ActionButton onClick={handleOnClickEditTargetting}>Edit campaigning</ActionButton>
                  </div>
                </Card>
                <div className={"right-edit-bottom-con"}>
                  <Card>
                    <div className={"campaign-edit-con-right"}>
                      <ActionButton onClick={handleOnClickEditCardDetails}>Edit card details</ActionButton>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <div className={"existing-campaign-edit-section-2"}>
              <Card>
                <div className={"bulk-edit-con"}>
                  <div className={"bulk-edit-grid-col-3"}>
                    <div className={"bulk-edit-item"}>
                      <h3>Quantity remaining:</h3>
                      <p>{card.quantity - card.collected}</p>
                    </div>
                    <div className={"bulk-edit-item"}>
                      <h3>Collects:</h3>
                      <p>{card.collected}</p>
                    </div>
                    <div className={"bulk-edit-item"}>{getExpiry(card.expiresOn)}</div>
                  </div>
                  <div className={"bulk-edit-grid-col-2"}>
                    <div className={"bulk-edit-item"}>
                      <h3>Redeems:</h3>
                      <p>{card.redeemed || 0}</p>
                    </div>
                    <div className={"bulk-edit-item"}>
                      <h3>Amount spent:</h3>
                      <p>${(card?.collected * 0.5).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className={"view-analytics-button-con"}>
              <Card>
                <p>
                  <button className={"transparent-button view-analytics-button"} onClick={props.previewList}>
                    View card analytics
                  </button>
                </p>
              </Card>
            </div>
            {isAdmin && (
              <>
                {/** Pick and pick% */}
                {/* <div className={"existing-campaign-edit-section-2"}>
                  <Card>
                    <div className={"bulk-edit-con"}>
                      <div className={"bulk-edit-grid-col-3"}>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Pick %:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">picks:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Activation on:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                      </div>
                      <div className={"bulk-edit-grid-col-2"}>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Win on:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                        <div className={"bulk-edit-item"}>
                          <span className="h3">Tier:</span>
                          {!editPick ? <span></span> : <input />}
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right", marginRight: "1rem" }}>
                      {!editPick ? (
                        <Button onClick={() => setEditPick(true)} style={{ fontWeight: "bold" }}>
                          Edit
                        </Button>
                      ) : (
                        <Button onClick={() => setEditPick(false)}>Save</Button>
                      )}
                    </div>
                  </Card>
                </div> */}
                {/** Pick and pick% end*/}

                <div className={"existing-campaign-edit-section-2"}>
                  <Card>
                    <div className={"bulk-edit-con"}>
                      <div className={"bulk-edit-grid-col-3"}>
                        <Switch
                          disabled={!editShop}
                          name="inShuffle"
                          title="shuffle"
                          checked={apGpData.inShuffle}
                          handleChange={changeApGP}
                        />
                        <div className="m-t-4">
                          <div className="h3 d-block m-l-8">Gold Value</div>
                          <div className={"bulk-edit-item"}>
                            <input
                              type="number"
                              className="switch-input"
                              onChange={(e) => changeApGP("gpValue", e.target.value)}
                              value={apGpData.gpValue || ""}
                              disabled={!apGpData.gp || !editShop}
                            />
                            <Switch
                              size="small"
                              disabled={!editShop || apGpData.gp}
                              name="gp"
                              title=""
                              checked={apGpData.gp ? true : false}
                              handleChange={changeApGP}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={"bulk-edit-grid-col-3"}>
                        <Switch
                          disabled={!editShop}
                          name="inShop"
                          title="Gift Shop"
                          checked={apGpData.inShop}
                          handleChange={changeApGP}
                        />
                        <div className="m-t-4">
                          <div className="h3 d-block m-l-8">AP Value</div>
                          <div className={"bulk-edit-item"}>
                            <input
                              type="number"
                              className="switch-input"
                              onChange={(e) => changeApGP("apValue", e.target.value)}
                              value={apGpData.apValue || ""}
                              disabled={!apGpData.ap || !editShop}
                            />
                            <Switch
                              size="small"
                              disabled={!editShop || apGpData.ap}
                              name="ap"
                              title=""
                              checked={apGpData.ap ? true : false}
                              handleChange={changeApGP}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right", marginRight: "1rem" }}>
                      {!editShop ? (
                        <Button onClick={() => setEditShop(true)} style={{ fontWeight: "bold" }}>
                          Edit
                        </Button>
                      ) : (
                        <Button onClick={() => saveApGp()}>Save</Button>
                      )}
                    </div>
                  </Card>
                </div>

                <div className={"existing-campaign-edit-section-2"}>
                  <Card>
                    <Collapsible header="Category">
                      <div className="category-list">
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="contest"
                                  checked={isContest}
                                  onChange={() => setIsContest(!isContest)}
                                />
                              }
                              label="Contest"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {isContest && (
                              <>
                                <div className="contest-date-picker">
                                  <DateRangePicker
                                    toggle={toggleDatePicker}
                                    show={showDatePicker}
                                    onChange={onChangeDatesRange}
                                    initialDateRange={contestDateRange}
                                  />
                                </div>
                                <div className="d-inline">
                                  <span style={{ verticalAlign: "middle" }}>
                                    {contestDateRange &&
                                      `${moment(contestDateRange.startDate).format("MMM DD,YY")} - ${moment(
                                        contestDateRange.endDate
                                      ).format("MMM DD,YY")}`}
                                  </span>
                                </div>
                                <Button onClick={() => toggleDatePicker()}>
                                  <DateRangeIcon />
                                </Button>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Button onClick={() => saveContest()}>Save</Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={9}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="featured"
                                  checked={isFeatured}
                                  onChange={() => setIsFeatured(!isFeatured)}
                                />
                              }
                              label="Featured"
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Button onClick={() => saveFeatured()}>Save</Button>
                          </Grid>
                        </Grid>
                        {card.campaignCategories && renderCampaignCategories(card.campaignCategories)}
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl className="new-category">
                              <Autocomplete
                                // {...defaultProps}
                                options={getOptions()}
                                freeSolo
                                id="controlled-demo"
                                // value={[]}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                  // console.log("newValue", newValue);
                                  if (!newValue) return setSelectedCategory();
                                  setSelectedCategory(newValue.id);
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      id="standard-adornment-password"
                                      type={"text"}
                                      label="Type a category"
                                      {...params}
                                      className="category-add-input"
                                      onChange={(e) => changeCategory(e.target.value)}
                                    />
                                    <span className="category-add-button" onClick={() => saveCategory()}>
                                      {/* <Button> */}
                                      <AddCircleOutlineIcon />
                                      {/* </Button> */}
                                    </span>
                                  </>
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </Collapsible>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <Base theme={"light"} topGap>
          <h1 style={{ marginLeft: "20px" }}>No campaign found</h1>
        </Base>
      );
    }
  } else {
    return <h1 style={{ marginLeft: "20px" }}>Loading...</h1>;
  }
}

function mapStateToProps(state) {
  return {
    userCards: state.campaigns.userCards,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callUserCards: () => dispatch(callUserCards()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CamaignsDetail);
