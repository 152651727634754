import React, { Component } from "react";

import Base from "./../../component/Layout/Base";

import ViewPersonalInfo from "./ViewPersonalInfo/ViewPersonalInfo";
import Plans from "./Plans/Plans";

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  changeTab = (index) => {
    this.setState({ tabIndex: index });
  };
  getTabClass(index) {
    return index === this.state.tabIndex ? "view-show" : "view-hide";
  }
  render() {
    return (
      <Base theme={"light"}>
        <div className="card-wrapper">
          <div className="card-container">
            <div className={this.getTabClass(0)}>
              <ViewPersonalInfo viewPlan={() => this.changeTab(1)} showPricing={this.props.match.params.pricing} />
            </div>
          </div>
        </div>
      </Base>
    );
  }
}
