import React, { useState } from 'react';
import { makeStyles, Grid, Button } from '@material-ui/core';

import { Link } from "react-router-dom";

import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { setUserData } from '../../../redux/actions/addCardActions'

const useStyles = makeStyles(theme => ({
    root: {
    },
    container: {
        textAlign: "center",
        width: "100%"
    },
    confirmCard: {
        backgroundColor: "#fefefe",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: "250px",
        maxWidth: "370px",
        margin: "0 auto",
        borderRadius: "1rem",
        position: "relative",
        overflow: "hidden"
    }
}));

function PreviewGiftCard(props) {
    console.log(props)
    const [showDetails, setShow] = useState(false)
    const classes = useStyles();
    const {
        title,
        cardValue,
        details,
        expiry,
        attachUrl,
        quantity,
        check_input,
        code,
        uid,
        approve,
        cardId,
        card_photo,
        date
    } = props

    const confirmUserDate = () => {
        props.setUserData({ title, cardValue, details, expiry, attachUrl, quantity, check_input, code, uid, approve, cardId, card_photo, date })
        props.confirmGiftCard()
    }

    return (
        <div className={classes.container}>
            <div className="confirm-gift-card">
                <Button onClick={props.backGiftCard} className="close">
                    <CloseIcon />
                </Button>
                <h3 className="confirm-title">Preview and Confirm</h3>
                <div className={classes.confirmCard} style={{ display: (showDetails === false ? 'none' : '') }}>
                    {
                        card_photo && <img src={card_photo} alt="" style={{ width: "100%", height: "100%", position: "absolute", zIndex: "0" }} />
                    }
                    <div className="confirm-header">
                        <p>Instructions on how to use:<br /> 1) Read details 2) Click redeem 3) Show to merchant or use code online in checkout.</p>
                    </div>
                    <div className="confirm-body">
                        <h3>Details: {details}</h3>
                        <p>Expires in {expiry} days</p>
                        <p>Visit: <Link to={`https://${attachUrl}`}>{attachUrl}</Link></p>
                    </div>
                    <div className="confirm-footer">
                        <p>{code}</p>
                    </div>
                </div>

                <div className={classes.confirmCard} style={{ display: (showDetails === true ? 'none' : '') }}>
                    {
                        card_photo && <img src={card_photo} alt="" style={{ width: "100%", height: "100%", position: "absolute", zIndex: "0" }} />
                    }
                    {/*<div className="confirm-header">
                        <img src={userLogo} width="78" alt="User Logo" style={{ display: (props.logoShowing === true ? 'block' : 'none') }} />
                    </div>*/}
                    <div className="confirm-body back">
                        {props.title}
                    </div>
                    <div >
                    </div>
                </div>


                <p onClick={() => setShow(!showDetails)}> Tap to view details </p>
                <div className="card-content">
                    <center>
                        <p className="text-left">Quantity:{props.quantity}</p><br />
                        <p>Location: Edmonton, Canada<br /><br />Gender: All</p>
                    </center>
                </div>
                <Button onClick={confirmUserDate} className="custom-button">Confirm</Button>
            </div>
            <Grid container justify="center">
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={props.backGiftCard}
                    className="custom-white"
                >Go Back</Button>
            </Grid>
        </div>
    );
}

function mapStateToProps(state) {
    console.log(state)
    return {
        title: state.card.title,
        cardValue: state.card.cardValue,
        details: state.card.details,
        expiry: state.card.expiry,
        attachUrl: state.card.attachUrl,
        quantity: state.card.quantity,
        check_input: state.card.check_input,
        code: state.card.code,
        uid: state.card.uid,
        approve: state.card.approve,
        cardId: state.card.cardId,
        card_photo: state.card.card_photo,
        logoShowing: state.card.logoShowing,
        date: state.card.date
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: ({ title, cardValue, details, expiry, attachUrl, quantity, check_input, code, uid, approve, cardId, card_photo, date }) => dispatch(setUserData({ title, cardValue, details, expiry, attachUrl, quantity, check_input, code, uid, approve, cardId, card_photo, date }))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewGiftCard)
