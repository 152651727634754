import React from "react";
import {
  withStyles,
  makeStyles,
  ListItem,
  Typography,
  List,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Base from "./../../component/Layout/Base";
import standardImg from "./../../assets/images/standard-gift.png";
import goldImg from "./../../assets/images/gold-gift.png";
import helpIng from "./../../assets/images/help.png";
import DropBox from "./DropBox";

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    padding: "0",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 105px)",
  },
  listitem: {
    marginTop: "0",
    listStyle: "none",
    justifyContent: "center",
    padding: "0",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    color: "#fff",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    border: "1px solid #979797",
    marginLeft: "25px",
    fontWeight: "700",
  },
}))(Tooltip);

export default function DropCard() {
  const classes = useStyles();

  return (
    <Base theme={"light"} topGap>
      <div className="drop-card-wrapper">
        <Link to="/drop-card/standard-gift">
          <DropBox
            boxIcon={require("../../assets/images/drop-box-standard.png")}
            toolTip={"Place your gift cards randomly"}
            giftType={"Standard Gift"}
          />
        </Link>
        <Link to="/drop-card/gold-gift">
          <DropBox
            boxIcon={require("../../assets/images/drop-box-gold.png")}
            toolTip={"Custom place you gift cards"}
            giftType={"Gold Gift"}
          />
        </Link>
      </div>
    </Base>
  );
}
