import { LOGIN_SUCCESS, RECOVERY_PASSWORD, SET_USER_DATA } from "../actions/actions-types";
import { LOGIN_LOGOUT } from "../actions/actions-types";
import { ERROR } from "../actions/actions-types";
import { SIGN_UP_SUCCESS } from "../actions/actions-types";

const initialState = {
  token: null,
  error: {
    status: false,
    err: "",
  },
  verificate: false,
  userData: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        verificate: action.verifcate,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("access-token", action.accessToken);
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case LOGIN_LOGOUT:
      return { ...initialState };
    case ERROR:
      return {
        ...state,
        error: action.error,
      };
    case RECOVERY_PASSWORD:
      return {
        ...state,
        id: action.id,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      };
    default:
      return state;
  }
}
