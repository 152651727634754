import React from "react";
import { Checkbox } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop } from "@fortawesome/free-solid-svg-icons";

const VerifyCheckbox = ({ checked, label, onChange }) => {
  return (
    <div className={"verify-checkbox"}>
      <div className={"verify-checkbox-container"}>
        <Checkbox
          checked={checked}
          onChange={onChange}
          color={"default"}
          icon={<FontAwesomeIcon
            icon={faStop}
            color={'#fff'}
          />}
        />
      </div>
      <p>{label}</p>
    </div>
  );
};

export default VerifyCheckbox;
