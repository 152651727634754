import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import GiftCard from "./GiftCard";
import OfferInput from "./Inputs/OfferInput";
import ExpiryDateInput from "./Inputs/ExpiryDateInput";
import QuantityInput from "./Inputs/QuantityInput";
import DetailsInput from "./Inputs/DetailsInput";
import VerifyCheckbox from "./Inputs/VerifyCheckbox";
import PromoteLinkInput from "./Inputs/PromoteLinkInput";
import DiscountCodeInput from "./Inputs/DiscountCodeInput";
import { useDispatch } from "react-redux";
import { TOAST } from "../../../redux/actions/actions-types";
import Hint from "./Hint";
import moment from "moment";
import Footer from "./Footer";
import validator from "validator";
import htmlToImage from "html-to-image";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#EAEAEA",
    paddingTop: 15,
    paddingBottom: 25,
  },
}));

const DesignOfferBackStep1 = ({ handleOnClickButton, details }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [card, setCard] = useState({
    backgroundImage: details.cardPhoto,
    title: details.title,
    logo: details.logoPhoto,
    expiryDate: details.expiry || moment().add(30, "days").format("YYYY-MM-DD"),
    offerValue: details.cardValue || 1,
    quantity: details.quantity || 1,
    details: details.details || "",
    promoteUrlLink: details.promoteUrl || "",
    discountCode: details.code || "",
    verify: false,
  });
  const getThumbnail = async () => {
    var node = document.getElementById("offer-card-back");

    let thumbnail = await htmlToImage.toPng(node);

    return thumbnail;
  };
  const handleOnChangeInput = (key, value) => {
    const cardCopy = { ...card };
    cardCopy[key] = value;
    setCard(cardCopy);
  };

  const validation = () => {
    if (!validator.isLength(card.details, { min: 3 })) {
      showError("Add details");
      return false;
    } else if (!validator.isLength(card.discountCode, { min: 3 })) {
      showError("Discount code must contain three characters");
      return false;
    } else if (!validator.isURL(card.promoteUrlLink)) {
      showError("Invalid promo url");
      return false;
    } else if (card.verify === false) {
      showError("Please mark the checkbox as verified!");
      return false;
    }
    return true;
  };

  const handleNextButton = async (tabIndex) => {
    if (tabIndex === 2) {
      if (validation()) {
        let thumbnail = await getThumbnail();
        handleOnClickButton(tabIndex, {
          details: card.details,
          expiry: card.expiryDate,
          code: card.discountCode,
          promoteUrl: card.promoteUrlLink,
          cardBack: thumbnail,
        });
      }
    } else {
      handleOnClickButton(tabIndex);
    }
  };
  const showError = (message) => {
    dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: message,
        show: true,
      },
    });
  };
  const handleOnChangeVerifyCheckbox = () => {
    const cardCopy = { ...card };
    cardCopy.verify = !cardCopy.verify;
    setCard(cardCopy);
  };

  return (
    <div>
      <div>
        <div className={"card-offer-container"}>
          <div className="card-offer-content">
            <div className={"card-offer-header"}>
              <p>Step 2 of 3</p>
              <p> Now, details on the back.</p>
            </div>
            <div className={"card-section"}>
              <div style={{ opacity: 0, position: "absolute" }}>
                <div id="offer-card-back">
                  <GiftCard
                    side={"back"}
                    rounded
                    shadow
                    backgroundImage={card.backgroundImage}
                    cardTitle={card.cardTitle}
                    cardLogo={card.cardLogo}
                    details={card.details}
                    expiry={card.expiryDate}
                    onChangeDescription={(e) => handleOnChangeInput("details", e.target.value)}
                    step={1}
                    cardPhoto={card.backgroundImage}
                    title={card.cardTitle}
                    code={card.discountCode}
                    promoteUrl={card.promoteUrlLink}
                    view={true}
                  />
                </div>
              </div>
              <GiftCard
                side={"back"}
                rounded
                shadow
                backgroundImage={card.backgroundImage}
                cardTitle={card.cardTitle}
                cardLogo={card.cardLogo}
                details={card.details}
                expiry={card.expiryDate}
                onChangeDescription={(e) => handleOnChangeInput("details", e.target.value)}
                step={1}
                cardPhoto={card.backgroundImage}
                title={card.cardTitle}
                code={card.discountCode}
                promoteUrl={card.promoteUrlLink}
              />
            </div>
            <div className={`bottom-section card-back-options-container-1 ${classes.bottomSection}`}>
              <Hint>Unqiue offer code:</Hint>
              <DiscountCodeInput
                value={card.discountCode}
                onChange={(e) => handleOnChangeInput("discountCode", e.target.value)}
                hideLable
              />
              <Hint>Select a day when your offer will expire</Hint>
              <ExpiryDateInput
                value={card.expiryDate}
                onChange={(expiryDate) => handleOnChangeInput("expiryDate", expiryDate)}
              />
              <Hint>Promote url link:</Hint>
              <PromoteLinkInput
                value={card.promoteUrlLink}
                onChange={(e) => handleOnChangeInput("promoteUrlLink", e.target.value)}
                hideLabel
              />
              <VerifyCheckbox
                onChange={handleOnChangeVerifyCheckbox}
                label={"Check to verify any discount codes created are valid for use immediately upon approval."}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        buttons={[
          {
            title: "Back",
            tabIndex: 0,
          },
          {
            title: "Next",
            tabIndex: 2,
          },
        ]}
        onClickButton={handleNextButton}
      />
    </div>
  );
};

export default DesignOfferBackStep1;
