import * as types from '../actions/actions-types'

const initialState = {

}
export default function dropCardsReducer(state = initialState, action) {
    switch (action.type) {
        case types.DROP_USER_CARDS_STANDARD:
            return {
                ...state,
                standardUserCards: action.userDropCards
            }
        default:
            return {
                state
            }
    }
}
