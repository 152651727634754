import React, { useEffect, useState } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import Base from "./../../component/Layout/Base";
import { useHistory } from "react-router-dom";
import { getAllInvoices, getCompanies } from "../../redux/requests/paymentRequest";
import { useSelector } from "react-redux";
import moment from "moment";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  backwrapper: {
    // backgroundColor: "#000",
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 1rem",
    position: "sticky",
    left: "0",
    top: "0",
  },
  totalCardAmount: {
    fontFamily: "Lato",
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "29px",
    textAlign: "left",
    padding: "70px 15px",
  },
});

const CssTextField = withStyles({
  root: {
    "& .MuiInput-input": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#888",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000",
      },
    },
  },
})(TextField);

const Transactions = () => {
  const classes = useStyles();
  const [transactions, setTransactions] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [companies, setCompanies] = useState();
  const [loader, setLoader] = useState(true);
  const [companyId, setCompanyId] = useState();
  const userData = useSelector((state) => state.login.userData);
  useEffect(() => {
    fetchInvoices();
  }, []);
  useEffect(() => {
    if (userData && userData.role === "admin") {
      fetchCompanies();
    }
  }, [userData]);
  useEffect(() => {
    if (companyId) {
      fetchInvoices();
    }
  }, [companyId]);

  const fetchCompanies = async () => {
    let companiesResp = await getCompanies();
    if (companiesResp && companiesResp.data) {
      setCompanies(companiesResp.data);
      setShowFilter(true);
    }
  };
  const fetchInvoices = async () => {
    setLoader(true);
    let filter;
    if (companyId) {
      filter = {
        where: {
          userId: companyId,
        },
      };
    }

    let invoiceResp = await getAllInvoices(filter);
    if (invoiceResp && invoiceResp.data) {
      setTransactions(invoiceResp.data);
    }
    setLoader(false);
  };
  return (
    <Base theme={"light"}>
      <div className="card-container campaign-list-area">
        {showFilter && (
          <Autocomplete
            id="clear-on-escape"
            clearOnEscape
            options={companies}
            getOptionLabel={(option) => {
              return option.firstname;
            }}
            onChange={(event, newValue) => {
              setCompanyId(newValue ? newValue.id : null);
            }}
            style={{ width: 300, margin: "auto" }}
            renderInput={(params) => (
              <CssTextField {...params} style={{ color: "#000" }} label="Search for companies" margin="normal" />
            )}
          />
        )}

        <TableContainer className="rounded-0">
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.tableheadcell}>#</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Invoice id</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Amount</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Raised on</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Paid on</StyledTableCell>
                <StyledTableCell className={classes.tableheadcell}>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            {loader ? (
              <h1>Loading...</h1>
            ) : (
              <TableBody>
                {Array.isArray(transactions) && transactions.length > 0 ? (
                  transactions.map(({ id, amount, stripeInvoiceId, raisedOn, paidOn, status }, index) => (
                    <StyledTableRow key={id} className={"table-row-min-height"}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>{stripeInvoiceId}</StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        ${parseFloat(amount || 0).toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        {moment(raisedOn).format("lll")}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell}>
                        {moment(paidOn).format("lll")}
                      </StyledTableCell>
                      <StyledTableCell className={classes.tablebodycell} style={{ textTransform: "capitalize" }}>
                        {status}
                      </StyledTableCell>
                      {/* <StyledTableCell className={classes.tablebodycell}>
                        <button
                          className={`go-back-button active accept-reject-button`}
                          onClick={() => {
                            history.push(`/locations/edit/${id}`);
                          }}>
                          Show status
                        </button>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell
                    className={classes.tablebodycell}
                    style={{ textTransform: "capitalize", borderBottom: 0 }}>
                    <h3>No invoice found</h3>
                  </StyledTableCell>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </Base>
  );
};

export default Transactions;
