import React, { useState } from "react";
import GiftCard from "../../GiftCard/DesignOffer/GiftCard";
import CardDetailsItem from "../../GiftCard/DesignOffer/CardDetailsItem";
import { makeStyles } from "@material-ui/core";
import AcceptButton from "./AcceptButton";
import RejectButton from "./RejectButton";
import SubmitButton from "../../../component/Layout/SubmitButton";
import GoBackButton from "../../../component/Layout/GoBackButton";
import RejectNoteInput from "./RejectNoteInput";
import history from "../../../helpers/history";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#EAEAEA",
    paddingTop: 1,
    paddingBottom: 40,
  },
}));

const AppForm = ({backList}) => {
  const classes = useStyles();

  const [card, setCard] = useState({
    backgroundImage: require("../../../assets/images/Image-1.png"),
    title: "Free Lattee",
    logo: require("../../../assets/images/logos/logo1.png"),
    description: "Redeem at any coffee shop location.",
    expiresIn: "30",
    offer: "$5",
    quantity: "300",
    discountCode: "RFS50",
    promoteUrlLink: "http://www.facebook.com/coffeeshop",
    approved: null,
  });

  const [cardSide, setCardSide] = useState("front");

  const toggleCardSide = () => {
    setCardSide(cardSide === "front" ? "back" : "front");
  };

  const acceptHandler = (approved) => {
    const copyCard = { ...card };
    copyCard.approved = approved;
    setCard(copyCard);
  };

  const onNavigate = (pageNumber) => {
		history.push(`/gift-card?page=${pageNumber}`);
  }

  return (
    <div className={"card-offer-container approval-screen"}>
      <div className="card-offer-content">
        <div>
          <div className={"card-offer-header"}>
            <p>Design your offer</p>
          </div>
          <div className={"card-section review"}>
            <GiftCard
              side={cardSide}
              view={true}
              backgroundImage={card.backgroundImage}
              cardTitle={card.title}
              cardLogo={card.logo}
              expiresIn={card.expiresIn}
              discountCode={card.discountCode}
              description={card.description}
              rounded
              shadow
            />
            <p>
              <button onClick={toggleCardSide}>View back of card</button>
            </p>
          </div>
          <div
            className={`bottom-section card-review-container ${classes.bottomSection}`}
          >
            <CardDetailsItem
              label={"Expiry date"}
              value={`${card.expiresIn} days`}
              onClickEdit={() => onNavigate(1)}
            />
            <CardDetailsItem
              label={"Quantity"}
              value={`${card.quantity}`}
              onClickEdit={() => onNavigate(1)}
            />
            <CardDetailsItem
              label={"Value of offer"}
              value={`${card.offer}`}
              onClickEdit={() => onNavigate(1)}
            />
            <CardDetailsItem
              label={"Discount Code"}
              value={`${card.discountCode}`}
              onClickEdit={() => onNavigate(2)}
            />
            <CardDetailsItem
              label={"Description"}
              value={`${card.description}`}
              onClickEdit={() => onNavigate(1)}
            />
            <CardDetailsItem
              label={"Promote url link"}
              value={`${card.promoteUrlLink}`}
              onClickEdit={() => onNavigate(2)}
            />

            <div className={"approval-actions-container"}>
              <div className={"approval-actions-btn-con"}>
                <AcceptButton
                  active={card.approved === true}
                  onClick={() => acceptHandler(true)}
                />
                <RejectButton
                  active={card.approved === false}
                  onClick={() => acceptHandler(false)}
                />
              </div>
              {card.approved === false && (
                <div>
                  <RejectNoteInput />
                </div>
              )}
              <div className={"approval-actions-bottom-btn-con"}>
                <GoBackButton big onClick={backList} />
                {card.approved !== null && <SubmitButton big  onClick={backList} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppForm;
