import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

function SwitchInput({ name, title, checked, handleChange, disabled, size = "large" }) {
  let height = size === "large" ? 40 : 30;
  let width = size === "large" ? 80 : 60;
  let translate = size === "large" ? "42px" : "32px";

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: width,
      height: height,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 0,
      "&$checked": {
        transform: `translateX(${translate})`,
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#66f875",
          opacity: 1,
          border: "none",
        },
        "& + thumb": {
          backgroundColor: "red",
        },
      },
      "&$focusVisible $thumb": {
        // color: the,
        border: "6px solid #fff",
      },
    },
    thumb: {
      backgroundColor: "#a80000",
      width: height - 3,
      height: height - 3,
    },
    track: {
      borderRadius: width / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      height: "90%",
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        inputProps={{ "aria-label": "primary checkbox" }}
        {...props}
      />
    );
  });

  return (
    <FormControlLabel
      control={
        <IOSSwitch
          disabled={disabled}
          checked={checked}
          onChange={(e) => handleChange(name, e.target.checked)}
          name={name}
          color="secondary"
        />
      }
      label={title}
      labelPlacement="top"
    />
  );
}

export default SwitchInput;
