import React from "react";
import { makeStyles } from "@material-ui/core";
import FooterButton from "./FooterButton";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
    width: "500px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  alignmentSpaceBetween: {
    justifyContent: "space-between",
  },
  alignmentCenter: {
    justifyContent: "center",
  },
}));

const Footer = ({ buttons, onClickButton }) => {
  const buttonAlignment = buttons.length === 1 ? "center" : "space-between";
  const classes = useStyles();

  return (
    <div
      className={`${classes.container} ${
        buttonAlignment === "center" ? classes.alignmentCenter : classes.alignmentSpaceBetween
      }`}>
      {buttons.map((button) => (
        <>
          {button.showLoader ? (
            <FooterButton showLoader={button.showLoader} onClick={() => null} />
          ) : (
            <FooterButton title={button.title} onClick={() => onClickButton(button.tabIndex)} />
          )}
        </>
      ))}
    </div>
  );
};

export default Footer;
