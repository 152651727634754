import Accordion from "@material-ui/core/Accordion";
import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const Collapsable = ({ header, children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
        {header}
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: "100%" }}>
          <Divider style={{ backgroundColor: "#000" }} />
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Collapsable;
