import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CitySearchItem from "./CitySearchItem";

const CitiesSearchList = ({ results, onSelect }) => {
  return (
    <div className={"city-search-result-container"}>
      {results.map((city) => (
        <CitySearchItem id={city.id} name={city.name} country={city.country} onSelect={onSelect} />
      ))}
    </div>
  );
};

export default CitiesSearchList;
