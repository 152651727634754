export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOGOUT = "LOGIN_LOGOUT";
export const ERROR = "ERROR";
export const ADD_CARD_SUCCESS = "ADDCARDSUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const PENDING_CARD = "PENDING_CARD";
export const USER_DATA = "USER_DATA";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const COLLECT_CARD = "COLLECT_CARD";
export const MANAGE_RESULT = "MANAGE_RESULT";
export const ADD_CAMPAIGNS_CARDS = "ADD_CAMPAIGNS_CARDS";
export const DROP_USER_CARDS_STANDARD = "DROP_USER_CARDS_STANDARD";
export const TOAST = "TOAST";
export const SET_USER_DATA = "SET_USER_DATA";
//Saga actions
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGIN_USER = "LOGIN_USER";
export const SINGUP_USER = "SINGUP_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GET_USER_DATA = "GET_USER_DATA";
export const ADD_CARD = "GET_USER_DATA";
export const CALL_PENDING_CARDS = "CALL_PENDING_CARDS";
export const CALL_COLLECTION_CARD = "CALL_COLLECTION_CARD";
export const MANAGE_CARD = "MANAGE_CARD";
export const CALL_USER_CARDS = "CALL_USER_CARDS";
export const EDIT_USER_CARD = "EDIT_USER_CARD";
export const GET_DROP_CARDS_STANDARD = "GET_DROP_CARDS_STANDARD";
