import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  homeCard: {
    width: "260px",
    height: "200px",
    backgroundColor: "#fff",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    marginBottom: '12px',
  },
  cardImage: {
    maxWidth: "125px",
  },
  footer: {
    position: "absolute",
    height: "50px",
    width: "100%",
    bottom: 0,
  },
  optionLabel: {
    fontWeight: "bold",
  },
}));

const HomeOption = ({ onClick, label, optionImage, imageStyle }) => {
  const classes = useStyles();

  return (
    <div className={classes.homeCard} onClick={onClick}>
      <img src={optionImage} className={classes.cardImage} style={imageStyle} />
      <div className={classes.footer}>
        <p className={classes.optionLabel}>{label}</p>
      </div>
    </div>
  );
};

export default HomeOption;
