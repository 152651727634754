import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import Auth from "../../component/Layout/Auth";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import { connect, useDispatch } from "react-redux";
import { singup, loginSuccess } from "../../redux/actions/authActions";
import validator from "validator";
import { TOAST } from "../../redux/actions/actions-types";
import { registerUser, loginUserReq } from "../../redux/requests/authRequests";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/hpjSkU2UYSU)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    justifyContent: "center",
  },
  title: {
    color: "#fff",
  },
  layout: {
    backgroundColor: "#1c1524",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#d72d83",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#3f51b5 !important",
  },

  link: {
    margin: theme.spacing(0),
    color: "#d72d83",
  },
  error: {
    backgroundColor: "tomato",
    margin: 0,
    padding: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    position: "absolute",
    top: 0,
    "& p": {
      color: "#fff",
    },
  },
}));

function SignUp(props) {
  const { history } = props;
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [company, setCompany] = useState("");
  const [allowNewsLetter, setAllowNewsLetter] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  const validate = (user) => {
    if (!validator.isAlpha(user.firstname) || !validator.isLength(user.firstname, { min: 3 })) {
      showToast("error", "Error", "Invalid First name");
      return false;
    } else if (!validator.isAlpha(user.lastname) || !validator.isLength(user.lastname, { min: 1 })) {
      showToast("error", "Error", "Invalid last name");
      return false;
    } else if (!validator.isEmail(user.email)) {
      showToast("error", "Error", "Invalid Email");

      return false;
    } else if (!validator.isLength(user.password, { min: 6 })) {
      showToast("error", "Error", "Invalid Password");
      return false;
    } else if (!validator.isLength(user.company, { min: 3 })) {
      showToast("error", "Error", "Invalid Company");
      return false;
    } else return true;
  };

  const registerHandler = async () => {
    setError(false);
    setShowSpinner(true);
    let user = {
      firstname: firstName,
      lastname: lastName,
      email,
      password,
      company,
      role: "company",
    };
    let isValid = validate(user);
    if (isValid) {
      user.allowNewsletters = allowNewsLetter;
      try {
        let registerResp = await registerUser(user);
        if (registerResp && registerResp.data) {
          let loginResp = await loginUserReq({ email, password });
          if (loginResp.data) {
            dispatch(loginSuccess(loginResp.data.data.token));
            // history.push("/verify-email");
            window.location.href = "/verify-email";
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    setShowSpinner(false);
  };

  const emailChange = (value) => {
    setEmail(value);
    // emailValidate(value);
  };

  const passwordChange = (value) => {
    setPassword(value);
  };

  useEffect(() => {
    const verificate = localStorage.getItem("verificate");
    verificate ? history.push("/") : void 0;
    localStorage.removeItem("verificate");
  });

  return (
    <Auth>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" className={classes.title} variant="h5">
        Sign Up
      </Typography>
      <form name="form-layout" className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(value) => setfirstName(value.nativeEvent.target.value)}
              autoComplete="fname"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(value) => setlastName(value.nativeEvent.target.value)}
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={(value) => emailChange(value.nativeEvent.target.value)}
              value={email}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(value) => passwordChange(value.nativeEvent.target.value)}
              value={password}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(value) => setCompany(value.nativeEvent.target.value)}
              required
              fullWidth
              name="company"
              label="Company name"
              type="company"
              id="company"
              autoComplete="company"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive updates via email."
              onChange={() => setAllowNewsLetter(!allowNewsLetter)}
            />
          </Grid>
        </Grid>

        <Button
          disabled={showSpinner}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={registerHandler}>
          {showSpinner ? <CircularProgress color="secondary" /> : "Sign Up"}
        </Button>
        <Grid container justify="center">
          <Grid item>
            <p className={classes.link}>
              Already have an account?{" "}
              <Link to="/" variant="body2" className="color-pink">
                Sign In
              </Link>
            </p>
          </Grid>
        </Grid>
      </form>
    </Auth>
  );
}

function mapStateToProps(state) {
  return {
    verificate: state.login.verificate,
    error: state.login.error,
  };
}

export default connect(mapStateToProps, {})(SignUp);
