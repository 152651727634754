import React, { useState, useEffect } from "react";
import { makeStyles, ListItem, List, useMediaQuery, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import Base from "./../../component/Layout/Base";
import createImg from "./../../assets/images/create-new.png";
import addGiftImg from "./../../assets/images/add-gift-card.png";
import liveImg from "./../../assets/images/Live.png";
import { useSelector } from "react-redux";
import HomeOption from "./HomeOption";
import { useHistory } from "react-router-dom";

function Home() {
  const matches = useMediaQuery(`(max-width:1280px)`);
  const matches2 = useMediaQuery(`(max-width:1024px)`);
  const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
      textAlign: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "80px",
    },
    title: {
      fontSize: "32px",
      color: "#000000",
      textAlign: "center",
      margin: "0 auto",
      marginBottom: "60px",
      maxWidth: "400px",
    },
    list: {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    topBanner: matches
      ? {
          position: "absolute",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          top: 0,
          textAlign: "center",
          backgroundColor: "red",
          padding: ".75rem",
          background: "linear-gradient(170deg, #4b478d, #cf2d86);",
        }
      : matches2
      ? {
          position: "absolute",
          justifyContent: "center",
          width: "calc(100% - 325px)",
          alignItems: "center",
          top: 0,
          textAlign: "center",
          backgroundColor: "red",
          padding: ".75rem",
          background: "linear-gradient(170deg, #4b478d, #cf2d86);",
        }
      : {
          position: "absolute",
          justifyContent: "center",
          width: "calc(100% - 350px)",
          alignItems: "center",
          top: 0,
          textAlign: "center",
          backgroundColor: "red",
          padding: ".75rem",
          background: "linear-gradient(170deg, #4b478d, #cf2d86);",
        },
    bannerTxt: matches2
      ? {
          color: "#fff",
          fontWeight: "bold",
          // display: "inline-block",
        }
      : {
          color: "#fff",
          fontWeight: "bold",
          display: "inline-block",
        },
    bannerButton: matches2
      ? {
          // position: "absolute",
          // right: "70px",
          width: "60%",
          top: "7px",
          color: "#fff",
          fontWeight: "bold",
          borderRadius: "10px",
          borderColor: "#fff",
          textTransform: "capitalize",
        }
      : {
          position: "absolute",
          right: "70px",
          top: "7px",
          color: "#fff",
          fontWeight: "bold",
          borderRadius: "10px",
          borderColor: "#fff",
          textTransform: "capitalize",
        },
    bannerCloseButton: {
      position: "absolute",
      right: "20px",
      top: 0,
      color: "#fff",
      fontWeight: "bold",
      padding: 0,
    },
  }));
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(false);
  const history = useHistory();
  const userData = useSelector((state) => {
    return state.login.userData || {};
  });
  useEffect(() => {
    const hasClosedBanner = localStorage.getItem("hasClosedBanner");
    if (hasClosedBanner) {
      setShowBanner(false);
    } else {
      if (Object.keys(userData).length > 0 && !userData.activePaymentDetail && userData.role !== "admin")
        setShowBanner(true);
      else setShowBanner(false);
    }
  }, [userData]);
  const closeBanner = () => {
    localStorage.setItem("hasClosedBanner", true);
    setShowBanner(false);
  };

  const seePricing = () => {
    history.push("/profile-settings/pricing");
  };
  return (
    <Base theme={"light"} topGap>
      {showBanner && (
        <div className={classes.topBanner}>
          <div className={classes.bannerTxt}>Free trial: {userData?.creditsRemaining || 0} offers remaining</div>

          <Button size="small" variant="outlined" className={classes.bannerButton} onClick={() => seePricing()}>
            See pricing
          </Button>
          <Button size="small" className={classes.bannerCloseButton} onClick={() => closeBanner()}>
            X
          </Button>
        </div>
      )}

      <div className={classes.container}>
        <h1 className={classes.title}>What can we do for you today?</h1>
        <List className={classes.list}>
          <Link to="gift-card">
            <HomeOption
              label={"Create a new offer"}
              optionImage={require("../../assets/images/home/create_new_offer.png")}
            />
          </Link>
          <Link to="drop-card/standard-gift">
            <HomeOption
              label={"Create a new campaign"}
              optionImage={require("../../assets/images/home/create_new_campaign.png")}
              imageStyle={{ maxWidth: "60px" }}
            />
          </Link>
          <Link to="campaigns">
            <HomeOption
              label={"Existing campaigns"}
              optionImage={require("../../assets/images/home/existing_campaigns.png")}
              imageStyle={{ maxWidth: "80px" }}
            />
          </Link>
        </List>
      </div>
    </Base>
  );
}

export default Home;
