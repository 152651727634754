import React from "react";

const AcceptButton = ({ onClick, active }) => {
  return (
    <button
      className={`accept-reject-button transparent-button ${active ? "active" : "in-active"}`}
      onClick={onClick}
    >
      Accept
    </button>
  );
};

export default AcceptButton;
