import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import QuantityInput from "./Inputs/QuantityInput";
import Footer from "./Footer";
import { TOAST } from "../../../redux/actions/actions-types";
import Hint from "./Hint";
import OfferInput from "./Inputs/OfferInput";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#EAEAEA",
    paddingTop: 30,
    paddingBottom: 1,
  },
}));

const DesignOfferBackStep2 = ({ handleOnClickButton, details }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [card, setCard] = useState({
    backgroundImage: "",
    title: details.title,
    logo: details.logoPhoto,
    description: details.details,
    expiry: details.expiry,
    offerValue: details.cardValue || 1,
    quantity: details.quantity || 1,
    discountCode: details.code,
    promoteUrlLink: details.promoteUrl,
    verify: false,
  });

  const handleOnChangeInput = (key, value) => {
    const cardCopy = { ...card };
    cardCopy[key] = value;
    setCard(cardCopy);
  };

  const validation = (tabIndex) => {
    if (!card.offerValue) {
      showError("Select offer value");
      return false;
    } else if (card.offerValue <= 0) {
      showError("Invalid offer value");
      return false;
    } else if (!card.quantity) {
      showError("Select Quantity");
      return false;
    } else if (card.quantity <= 0) {
      showError("Invalid Quantity");
      return false;
    }
    return true;
  };

  const handleNextButton = (tabIndex) => {
    if (tabIndex === 3) {
      if (validation(tabIndex)) {
        handleOnClickButton(tabIndex, {
          cardValue: card.offerValue,
          quantity: card.quantity,
        });
      }
    } else {
      handleOnClickButton(tabIndex);
    }
  };

  const showError = (message) => {
    dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: message,
        show: true,
      },
    });
  };

  return (
    <div>
      <div>
        <div className={"card-offer-container"}>
          <div className="card-offer-content">
            <div className={"card-offer-header"}>
              <p>Step 3 of 3</p>
              <p>Just some more final details.</p>
            </div>

            <div className={`bottom-section card-back-options-container-2 ${classes.bottomSection}`}>
              <Hint>What is the approximate monetary value of your offer?</Hint>
              <OfferInput
                minValue={1}
                maxValue={1000000}
                value={card.offerValue}
                onChange={(offerValue) => handleOnChangeInput("offerValue", offerValue)}
              />

              <Hint>Select the amount of available offers to be collected</Hint>
              <QuantityInput
                minValue={1}
                maxValue={1000000}
                value={card.quantity}
                onChange={(quantity) => handleOnChangeInput("quantity", quantity)}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        buttons={[
          {
            title: "Back",
            tabIndex: 1,
          },
          {
            title: "Review",
            tabIndex: 3,
          },
        ]}
        onClickButton={handleNextButton}
      />
    </div>
  );
};

export default DesignOfferBackStep2;
