import React, { useState } from "react";
import DatePicker from "react-datepicker";

const RangePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  // handleStartDate = (date)=>{
  //     if()
  // }
  return (
    <>
      <span>From Date</span>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
      />
      <span>To Date</span>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
      />
    </>
  );
};

export default RangePicker;
