import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import GoBackButton from "../../../component/Layout/GoBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    textAlign: "center",
    width: "100%",
    marginBottom: "100px",
  },
  addform: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    maxWidth: "430px",
    margin: "0 auto",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  avatarImage: {
    position: "absolute",
    borderRadius: "50%",
    height: "180px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "&:hover": {
      opacity: ".5",
    },
  },
}));

const Plans = ({ backSettings, onClose, onPressSelect }) => {
  const classes = useStyles();

  const [logo, setLogo] = useState(null);

  return (
    <div className={classes.container}>
      <div className={"plan-box-container"}>
        <div className={"bar"}>
          <button onClick={onClose} className={"transparent-button"}>
            <FontAwesomeIcon icon={faTimes} color={"#000"} />
          </button>
        </div>
        <div className={"content-area"}>
          <div className={"left"}>
            <div className={"plan-type-box"}>
              <p>Start with Free</p>
            </div>
            <div className={"plan-desc-item-con"}>
              <div className={"plan-desc-item first-item"}>
                <p>Create an unlimited amount of gift rewards.</p>
              </div>
              <div className={"plan-desc-item"}>
                <p>Up to 25 gift collections from customers.</p>
              </div>
              <div className={"plan-desc-item selected"}>
                <p>Included in all plans Analytics, and support. </p>
              </div>
            </div>
            <div className={"select-plan-con"}>
              <button onClick={onClose}>Current Plan</button>
            </div>
          </div>
          <div className={"divider"}></div>
          <div className={"right"}>
            <div className={"plan-type-box blue"}>
              <p>Pay as you go</p>
            </div>
            <div className={"plan-desc-item-con"}>
              <div className={"plan-desc-item first-item"}>
                <p>Create an unlimited amount of gift rewards.</p>
              </div>
              <div className={"plan-desc-item"}>
                <p>$0.50 cents per gift card collected by customer.</p>
              </div>
              <div className={"plan-desc-item selected"}>
                <p>Included in all plans Analytics, and support. </p>
              </div>
            </div>
            <div className={"select-plan-con"}>
              <button onClick={() => onPressSelect()} className={"selected"}>
                {"Select"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
