import React from "react";

const GoBackButton = ({ big, onClick }) => {
  return (
    <button className={`go-back-button active ${big && 'accept-reject-button'}`} onClick={onClick}>
      Go Back
    </button>
  );
};

export default GoBackButton;
