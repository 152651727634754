import React, { useEffect, useState } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../../helpers/dateFormat";
import RangePicker from "../RangePicker";
import { callCollectionCards } from "../../../redux/actions/approvalsActions";
import {
  fetchPendingCardCollection,
  updateCardCollection,
  fetchCampaign,
} from "../../../redux/requests/pendingApprovalRequest";

import { TOAST } from "../../../redux/actions/actions-types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CssTextField = withStyles({
  root: {
    "& .MuiInput-input": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#888",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000",
      },
    },
  },
})(TextField);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.background.default,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  backwrapper: {
    minHeight: "60px",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: "0 1rem",
    position: "sticky",
    left: "0",
    top: "0",
  },
  textTypo: {
    color: "#fff",
    marginTop: "0 !important",
    marginBottom: "20px !important",
  },
  searchInput: {
    color: "#000",
  },
});

function CollectionList(props) {
  const classes = useStyles();
  const { collectCards } = props;
  const [cards, setCards] = useState();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, "months").format("YYYY-MM-DD")));
  const [toDate, setToDate] = useState(new Date());
  const [campaigns, setCampaigns] = useState([{ id: "xga", label: "jkdfsl" }]);

  const [dates, setDates] = useState({
    fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  const [value, setValue] = useState({ id: "", label: "" });
  const dispatch = useDispatch();

  const role = useSelector((state) => state.login.userData.role);

  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  useEffect(() => {
    // getCollection();
    getTemplates();
  }, []);

  useEffect(() => {
    getCollection();
  }, [value, fromDate, toDate]);

  const getCollection = async () => {
    const dateIs = { fromDate, toDate };
    if (moment(toDate).diff(moment(fromDate), "days") < 0) {
      return showToast("error", "Error", "from date can't exceed to date ");
    }
    let cardData = await fetchPendingCardCollection(dateIs, value.id);

    if (cardData && cardData.data) {
      if (Array.isArray(cardData.data)) {
        setCards(cardData.data);
      }
      // if (Array.isArray(cardData.data) && cardData.data.length === 0) {
      //   showToast("error", "Error", "No Collections found");
      // }
    }
  };

  const getTemplates = async () => {
    let campaignsData = await fetchCampaign();
    if (campaignsData && campaignsData.data) {
      campaignsData = campaignsData.data.map((items) => {
        let label = `${items.name}`;
        let id = items.id;
        label += items.user?.firstname ? `,${items.user?.firstname}` : "";
        items += items.user?.lastname
          ? items.user?.firstname
            ? ` ${items.user?.lastname}`
            : items.user?.lastname
          : "";

        return {
          label: label,
          id: id,
        };
      });
      setCampaigns(campaignsData);
    }
  };

  const getExpiry = (date) => {
    let dateDiff = moment(date).diff(moment());
    return dateDiff < 0 ? `Expired : ${moment(date).format("LLL")}` : `Expires on : ${moment(date).format("LLL")}`;
  };

  const redeemed = () => {
    let totalQuantity = 0;
    if (!Array.isArray(cards)) return totalQuantity;
    for (let card of cards) {
      if (card.redeemedOn) totalQuantity = totalQuantity + card.quantity;
    }
    return totalQuantity;
  };

  return (
    <div className="card-container">
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          // padding: "5px 10px",
        }}>
        <Autocomplete
          id="clear-on-escape"
          clearOnEscape
          options={campaigns}
          getOptionLabel={(option) => {
            return option.label;
          }}
          style={{ width: 170, margin: "auto", padding: "0px", height: "40px" }}
          onChange={(event, newValue) => {
            newValue && setValue({ id: newValue.id, label: newValue.label });
            !newValue && setValue({ id: "", label: "" });
          }}
          renderInput={(params) => <CssTextField {...params} label="Search" margin="normal" />}
        />
      </div>

      <div className={classes.backwrapper}>
        <RangePicker startDate={fromDate} endDate={toDate} setStartDate={setFromDate} setEndDate={setToDate} />
        <h3 align="left">
          Total:{" "}
          <span className="float-right">
            <span className="m-r-4">{redeemed()} redeemed</span>
            <span>{cards?.length} Collected</span>
          </span>
        </h3>
        {/* <h3 align="left" className={classes.textTypo}>Total: <span className="float-right"><span>Templates: {cards.length}</span>&nbsp;&nbsp;<span>Quantity: 300</span></span></h3> */}
      </div>
      <TableContainer component={Paper} className="rounded-0">
        <Table className={classes.table} aria-label="customized table">
          <TableBody>
            {cards?.length > 0 ? (
              cards?.map((row, index) => (
                <StyledTableRow key={index} className={"table-row-bg-white"}>
                  <StyledTableCell align="center" className={classes.tablebodycell}>
                    <Link to="campaigns" className="no-decoration">
                      {row.campaign.name}
                    </Link>
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.tablebodycell}>
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.tablebodycell}>
                    {row.location}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.tablebodycell}>
                    Collected: {moment(row.createdAt).format("lll")}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.tablebodycell}>
                    Redeemed: {row.redeemedOn ? moment(row.redeemedOn).format("lll") : "NA"}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <>No collection list</>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    callCollectionCards: () => dispatch(callCollectionCards()),
  };
}

function mapStateToProps(state) {
  return {
    collectCards: state.approvals.collectCards,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionList);
