import React from "react";
import PropTypes from "prop-types";
import Base from "./../../component/Layout/Base";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";

import Template from "./template/Template";
import Approvals from "./approvals/Approvals";
import CollectionList from "./collectionList/CollectionList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: "inset 0 -2px 0 #fff !important",
    marginTop: "44px",
  },
  peddingXNone: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

export default function PendingApprovals() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Base theme={"light"}>
      <div className="card-wrapper">
        <div className="card-container">
          <div className={classes.root}>
            <AppBar
              className={classes.appBar}
              color={"inherit"}
              position="static"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="My offers"
                  {...a11yProps(0)}
                  className={"tab-item-title"}
                />
                <Tab
                  label="campaigns"
                  {...a11yProps(1)}
                  className={"tab-item-title"}
                />
                <Tab
                  label="Collection List"
                  {...a11yProps(2)}
                  className={"tab-item-title"}
                />
              </Tabs>
            </AppBar>
            <TabPanel className={classes.peddingXNone} value={value} index={0}>
              <Template />
            </TabPanel>
            <TabPanel className={classes.peddingXNone} value={value} index={1}>
              <Approvals />
            </TabPanel>
            <TabPanel className={classes.peddingXNone} value={value} index={2}>
              <CollectionList />
            </TabPanel>
          </div>
        </div>
      </div>
    </Base>
  );
}
