import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const ExpiryDateInput = ({ value, onChange }) => {
  const handleOnChangeDate = (date) => {
    const expiryDate = moment(date).format("YYYY-MM-DD");
    onChange(expiryDate);
  };

  return (
    <div className={"offer-input-container expiry-date-input-container"}>
      <label className={"noselect"}>Expiry date:</label>
      <div className={"input-area"}>
        <DatePicker
          selected={moment(value, "YYYY-MM-DD").toDate()}
          onChange={handleOnChangeDate}
          id={"expiry-date-input"}
          dateFormat={"yyyy-MM-dd"}
          customInput={<input className={"gift-card-number-input"} type={"text"} />}
          minDate={new Date()}
          maxDate={new Date(moment().add(6, "months").format("LLL"))}
        />
      </div>
      <div className={"input-control noselect"}>
        <div className={"calendar-input-icon"} onClick={() => document.getElementById("expiry-date-input").focus()}>
          <img src={require("../../../../assets/images/calendar.svg")} />
        </div>
      </div>
    </div>
  );
};

export default ExpiryDateInput;
