import * as types from '../actions/actions-types'

const initialState = {

}
export default function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_CAMPAIGNS_CARDS:
            return {
                ...state,
                userCards: action.userCards
            }
        default:
            return {
                state
            }
    }
}
