import React, { useState, useEffect } from "react";
import GiftCard from "./GiftCard";
import { makeStyles, CircularProgress } from "@material-ui/core";
import CardFrontOption from "./CardFrontOption";
import { TOAST } from "../../../redux/actions/actions-types";
import { useDispatch } from "react-redux";
import { addCard, setCardPhoto, setFrontCardImage, setBackCardImage } from "../../../redux/requests/cardRequests";
import CardDetailsItem from "./CardDetailsItem";
import Footer from "./Footer";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#EAEAEA",
    paddingTop: 1,
    paddingBottom: 40,
  },
}));

const DesignOfferReview = ({ onNavigate, handleOnClickButton, details, viewOnly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [card, setCard] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const [cardSide, setCardSide] = useState("front");

  useEffect(() => {
    if (details) {
      setCard({
        backgroundImage: details.cardPhoto
          ? details.cardPhoto.includes(window.location.protocol)
            ? details.cardPhoto
            : `${process.env.REACT_APP_BASE_URL}/uploads/card-photo/${details.cardPhoto}`
          : null,
        title: details.title,
        logo: details.logoPhoto
          ? details.logoPhoto.includes(window.location.protocol)
            ? details.logoPhoto
            : `${process.env.REACT_APP_BASE_URL}/uploads/avatars/${details.logoPhoto}`
          : null,
        description: details.details,
        expiry: details.expiry,
        offer: details.cardValue,
        quantity: details.quantity,
        discountCode: details.code,
        promoteUrlLink: details.promoteUrl,
      });
    }
  }, [details]);

  const toggleCardSide = () => {
    setCardSide(cardSide === "front" ? "back" : "front");
  };

  const submitCard = async (tabIndex) => {
    if (tabIndex === 4) {
      setShowLoading(true);
      let photo = details.photo;
      delete details.photo;
      const exactObj = {
        ...details,
        // cardPhoto : details.photo,
        expiry: moment(details.expiry).format(),
      };
      let thumbnailFront = details.cardFront;
      delete details.cardFront;

      let thumbnailBack = details.cardBack;
      delete details.cardBack;

      let frontFromData = new FormData();
      frontFromData.append("card-photo", thumbnailFront);
      let backFromData = new FormData();
      backFromData.append("card-photo", thumbnailBack);
      let addCardResp = await addCard(exactObj);
      if (addCardResp && addCardResp.data) {
        let formData = new FormData();
        formData.append("cardPhoto", photo);
        let setPhotoResp = await setCardPhoto(addCardResp.data.id, formData);
        let cardThumbnailFront = await setFrontCardImage(addCardResp.data.id, frontFromData);
        let cardThumbnailBack = await setBackCardImage(addCardResp.data.id, backFromData);
      }
      dispatch({
        type: TOAST,
        load: {
          type: "success",
          title: "Success",
          message: "card added",
          show: true,
        },
      });
      handleOnClickButton(tabIndex);
      setShowLoading(false);
    } else {
      handleOnClickButton(tabIndex);
    }
  };
  return (
    <div>
      {card && (
        <div>
          <div className={"card-offer-container"}>
            <div className="card-offer-content">
              <div className={"card-offer-header"}>
                <p>Review</p>
              </div>
              <div className={"card-section review"}>
                <GiftCard
                  side={cardSide}
                  view={true}
                  cardPhoto={card.backgroundImage}
                  title={card.title}
                  logoPhoto={card.logo}
                  details={card.description}
                  expiry={card.expiry}
                  code={card.discountCode}
                  promoteUrl={card.promoteUrlLink}
                  rounded
                  shadow
                  step={3}
                />
                <p>
                  <button onClick={toggleCardSide}>View {cardSide === "front" ? "back" : "front"} of card</button>
                </p>
              </div>
              <div className={`bottom-section card-review-container ${classes.bottomSection}`}>
                <CardDetailsItem
                  label={"Expires in "}
                  value={`${moment(card.expiry).diff(moment(), "days")} days`}
                  onClickEdit={() => onNavigate(1)}
                  edit={!viewOnly}
                />
                <CardDetailsItem label={"Quantity"} value={`${card.quantity}`} onClickEdit={() => onNavigate(2)} />
                <CardDetailsItem label={"Value of offer"} value={`$${card.offer}`} onClickEdit={() => onNavigate(2)} />
                <CardDetailsItem
                  label={"Discount Code"}
                  value={`${card.discountCode}`}
                  onClickEdit={() => onNavigate(1)}
                />
                <CardDetailsItem
                  label={"Description"}
                  value={`${card.description}`}
                  onClickEdit={() => onNavigate(1)}
                  edit={!viewOnly}
                />
                <CardDetailsItem
                  label={"Promote url link"}
                  value={`${card.promoteUrlLink}`}
                  onClickEdit={() => onNavigate(1)}
                  isLink={true}
                  edit={!viewOnly}
                />
              </div>
            </div>
          </div>

          {!viewOnly && (
            <Footer
              buttons={[
                {
                  title: "Back",
                  tabIndex: 2,
                },
                {
                  title: "Submit",
                  tabIndex: 4,
                  disabled: showLoading,
                  showLoader: showLoading,
                },
              ]}
              onClickButton={submitCard}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DesignOfferReview;
