import React from 'react';
import { makeStyles, Hidden, Container, Grid } from '@material-ui/core';

import Header from "./include/Header";
import Sidebar from "./include/Sidebar";
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: "#1c1524",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(0),
    maxWidth: "100%",
  },
}));

function Base(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <Header />
      </Hidden>
      <Sidebar 
        isAuthenticated={props.isAuthenticated}
      />
      <main className={classes.content}>
        <Hidden lgUp>
          <div className={classes.appBarSpacer} />
        </Hidden>
        <Container className={classes.container}>
          <Grid container>
            {props.children}
          </Grid>
        </Container>
      </main>
    </div>
  );
}

function mapStateToProps(state){
  return{
    isAuthenticated: !!state.login.token
  }
}

export default connect(mapStateToProps)(Base)