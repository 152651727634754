import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import customStyle from "./card.module.css";
import validator from "validator";

const useStyles = makeStyles((theme) => ({
  rounded: {
    borderRadius: "12px",
  },
}));

const GiftCard = ({
  side,
  isApproved,
  cardPhoto,
  title,
  onChangeTitle,
  logoPhoto,
  details,
  expiry,
  code,
  promoteUrl,
  onChangeDescription,
  step,
  rounded,
  shadow,
  viewDetails,
  onUploadLogo,
  onRemoveLogo,
  view,
  generateThumbnail,
}) => {
  const classes = useStyles();
  const style = {
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  if (cardPhoto !== null && side === "front") {
    style.backgroundImage = `url('${cardPhoto}')`;
  }

  const validateUrl = () => {
    if (!promoteUrl) {
      return false;
    } else if (!validator.isURL(promoteUrl)) {
      // showError("Invalid promo url");
      return false;
    }
    return true;
  };

  return (
    <div
      className={`gift-card ${rounded === true && classes.rounded} 
      ${shadow === true && "shadow"}`}
      style={style}>
      {side === "front" && (
        <div>
          <div className={`card-logo ${view && "no-background"}`}>
            {logoPhoto ? (
              <img
                src={
                  logoPhoto.includes(window.location.protocol)
                    ? logoPhoto
                    : `${process.env.REACT_APP_BASE_URL}/uploads/avatars/${logoPhoto}`
                }
                alt=""
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
              />
            ) : (
              !view && <p onClick={onUploadLogo}> Show Logo</p>
            )}
            {logoPhoto && onRemoveLogo && (
              <span onClick={onRemoveLogo}>
                <FontAwesomeIcon icon={faTimes} color={"#fff"} size={"sm"} />
              </span>
            )}
          </div>

          <div className={`${!view ? "create-a-title" : "view-title"}`}>
            {!view ? (
              <input placeholder={"Enter title of offer"} value={title} onChange={onChangeTitle} />
            ) : (
              <p className="card-title">{title}</p>
            )}
          </div>
        </div>
      )}
      {side === "back" && (
        <>
          <div className={"card-expiry"}>
            {expiry !== null && expiry !== undefined && <p>Expires in {moment(expiry).diff(moment(), "days")} days</p>}
          </div>
          <div className={`card-description ${view && "light-background"} `}>
            {!view ? (
              <textarea
                placeholder={"Enter offer details…"}
                onChange={onChangeDescription}
                maxLength={1500}
                className={customStyle.inputField}
                id="inputField"
                style={{
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                readOnly={step === 2}
                value={details}>
                {details}
              </textarea>
            ) : (
              <p>{details ? details : "Write details of your offer"}</p>
            )}
          </div>

          {(step === 1 || step === 3) && (
            <div className={"card-checkout-section"}>
              <p>
                Use code <span>{code}</span> during checkout
              </p>
              {validateUrl() ? (
                <a href={promoteUrl} target={"_blank"}>
                  Learn more
                </a>
              ) : (
                <a>Learn more</a>
              )}
            </div>
          )}
        </>
      )}
      {/* {view === true && viewDetails === true && (
        <div className={"tap-to-view-details"}>
          <p>Tap to view details</p>
        </div>
      )} */}
    </div>
  );
};

export default GiftCard;
