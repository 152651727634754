import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import authReducer from "./authReduser";
import addCardReducer from "./addCardReducer";
import approvalReducer from "./approvalsReducer";
import campaignsReducer from "./campaignsReducer";
import dropCardsReducer from "./dropCardsReduser";
import toastReducer from "./toastReducer";

const rootReducer = combineReducers({
  login: authReducer,
  card: addCardReducer,
  approvals: approvalReducer,
  campaigns: campaignsReducer,
  dropCards: dropCardsReducer,
  toast: toastReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
