import React, { Component } from "react";
import Base from "./../../../component/Layout/Base";
import { Grid, Button, Divider, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CancelIcon from "@material-ui/icons/Cancel";
import "react-rangeslider/lib/index.css";
import PreviewGiftCard from "./../../GiftCard/previewGiftCard/PreviewGiftCard";
import { connect } from "react-redux";
import { getUserCards } from "../../../redux/actions/dropCardsActions";
import GiftCard from "../../GiftCard/DesignOffer/GiftCard";
import Navigator from "../../GiftCard/Navigator";
import Card from "../../../component/Layout/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import CountryBadge from "../../../component/Layout/CountryBadge";
import CityItem from "../../../component/Layout/CityItem";
import CitiesSearchList from "../../../component/Layout/CitiesSearchList";
import GenderRadioInput from "./GenderRadioInput";
import GradiantButton from "../../../component/Layout/GradiantButton";
import GoBackButton from "../../../component/Layout/GoBackButton";

import {
  fetchCards,
  fetchLocation,
  addCampaign,
  fetchCampaignDetails,
  updateCampaignDetails,
} from "../../../redux/requests/cardRequests";
import validator from "validator";
import { TOAST } from "../../../redux/actions/actions-types";
const blankCardImage = require("../../../assets/images/home/create_new_offer.png");
class StandardGift extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: "create",
      value: 10,
      showMe: false,
      card: 0,
      showing: true,
      approvedCards: [],
      choiceQuantity: 0,
      active: false,
      cities: "canada",
      addCity: [],
      cards: [],
      availableCountries: [],
      citySearchInputValue: "",
      availableCities: [{ id: 1, name: "Lac La Biche", country: "Canada" }],
      citySearchResults: [],
      showSearchResults: false,
      genders: ["Male", "Female", "All"],
      paramsDecided: false,
      editTarget: false,
      cardSide: "front",
      showSubmitSpinner: false,
      campaignId: null,
      show: false,
      selectableCities: [],
      showLoading: true,
      filteredCities: [],
    };
  }

  componentDidUpdate() {
    const paramsString = this.props.location.search;

    if (paramsString) {
      const params = new URLSearchParams(paramsString);
      const edit = params.get("edit");
      const campaignId = params.get("id");

      if (edit == "true") {
        if (!this.state.paramsDecided) {
          this.setState({
            paramsDecided: true,
            editTarget: true,
          });
          this.setState({ campaignId: campaignId });
        }
      } else {
        // this.setState({
        //   paramsDecided: true,
        // });
      }
    } else {
      if (!this.state.paramsDecided) {
        this.setState({
          paramsDecided: true,
        });
      }
    }
  }

  getCampaignDetails = async (id) => {
    let campaignDetails = await fetchCampaignDetails(id);

    if (campaignDetails && campaignDetails.data) {
      let currentCardIndex = this.state.cards.findIndex((items) => items.id === campaignDetails.data.cardId);
      let currentCities = campaignDetails.data.locationIds;
      currentCities = this.state.availableCities.filter(
        (items) => currentCities && currentCities.indexOf(items.id) > -1
      );

      let currentCountries = currentCities.map((items) => items.country.toLowerCase());
      currentCities = currentCities.map((items) => items.city);
      let card = [...this.state.cards];
      card[currentCardIndex].selectedCountries = currentCountries;
      card[currentCardIndex].selectedCities = currentCities || [];
      // card[currentCardIndex].selectedCities.push(currentCities[0]);
      if (card[currentCardIndex].selectedCountries.length > 0) this.toggleCountryName(currentCities);
      card[currentCardIndex].selectedGender = campaignDetails.data.gender;
      card[currentCardIndex].allowedQuantity = campaignDetails.data.quantity;
      card[currentCardIndex].locationIds = campaignDetails.data.locationIds;
      card[currentCardIndex].inShuffle = campaignDetails.data.inShuffle;
      card[currentCardIndex].inShop = campaignDetails.data.inShuffle;

      this.setState({ cards: card, card: currentCardIndex, show: true });
    }
  };

  quantityChoice = (check) => {
    const up = this.state.choiceQuantity + 1;
    const down = this.state.choiceQuantity - 1;

    if (check === "up" && this.state.choiceQuantity < this.props.standardUserCards[this.state.card].quantity) {
      this.setState({ choiceQuantity: up });
    } else if (check === "down" && this.state.choiceQuantity > 0) {
      this.setState({ choiceQuantity: down });
    }
  };

  preview() {
    this.setState({
      showMe: !this.state.showMe,
    });
  }

  nextCard = () => {
    const step = this.state.card + 1;
    this.setState({ card: step });
    if (step === this.props.standardUserCards.length) {
      this.setState({ card: 0 });
    }
  };

  prewCard = () => {
    const step = this.state.card - 1;
    this.setState({ card: step });
    if (step < 0) {
      this.setState({ card: this.props.standardUserCards.length - 1 });
    }
  };

  addCity = (city) => {
    let test = city.includes("Canada") || city.includes("USA");
    if (test) {
      this.setState({ addCity: [...this.state.addCity, city] });
      this.refs.cityInput.value = "";
    }
  };

  removeCity = (city) => {
    const test = this.state.addCity.filter((rem) => rem !== city);
    this.setState({ addCity: test });
  };

  componentDidMount() {
    this.getUserCards();
  }

  getUserCards = async () => {
    this.setState({ showLoading: true });
    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const edit = params.get("edit");
    const campaignId = params.get("id");

    let cards = await fetchCards();

    let locations = await fetchLocation();
    if (cards && cards.data) {
      // console.log("cards.data :>> ", cards.data);
      this.setState({ cards: cards.data });
    }
    if (locations && locations.data) {
      this.setState({ availableCities: locations.data });
      let counties = locations.data.map((items) => items.country.toLowerCase());
      counties = [...new Set(counties)];
      this.setState({ availableCountries: counties });
    }

    if (edit && campaignId) {
      this.getCampaignDetails(campaignId);
    } else {
      this.setState({ show: true });
    }
    this.setState({ showLoading: false });
  };

  renderCard() {
    const card = this.state.cards[this.state.card];

    if (card.cardPhoto && !card.cardPhoto.includes(window.location.protocol)) {
      card.cardPhoto = `${process.env.REACT_APP_BASE_URL}/uploads/card-photo/${card.cardPhoto}`;
    }

    return <GiftCard {...card} side={this.state.cardSide} view viewDetails rounded shadow step={3} />;
  }

  incrementQuantity = () => {
    const card = this.state.cards[this.state.card];

    if (!card.allowedQuantity || card.allowedQuantity < card.quantity) {
      this.updateCards({
        allowedQuantity: card.allowedQuantity ? card.allowedQuantity + 1 : 1,
      });
    }
  };

  decrementQuantity = () => {
    const card = this.state.cards[this.state.card];

    if (!card.allowedQuantity || card.allowedQuantity > 0) {
      this.updateCards({
        allowedQuantity: card.allowedQuantity ? card.allowedQuantity - 1 : 0,
      });
    }
  };

  handleOnChangeQuantity = (e) => {
    const value = parseInt(e.target.value);
    const card = this.state.cards[this.state.card];

    if (value > 0 && value <= card.quantity) {
      this.updateCards({ allowedQuantity: value });
    } else if (!value) {
      this.updateCards({ allowedQuantity: "" });
    }
  };

  toggleCountryName = (countryName) => {
    let selectedCountries = this.state.cards[this.state.card].selectedCountries
      ? [...this.state.cards[this.state.card].selectedCountries]
      : [];
    let selectedCities = this.state.cards[this.state.card].selectedCities
      ? [...this.state.cards[this.state.card].selectedCities]
      : [];
    // console.log("selectedCountries, countryName :>> ", selectedCountries, countryName);

    // const countryIndex = selectedCountries.findIndex((cName) => cName === countryName);
    // if (countryIndex >= 0) {
    //   const filteredCountries = selectedCountries.filter((cName) => cName !== countryName);
    //   this.setCities(filteredCountries);
    //   this.updateCards({ selectedCountries: filteredCountries });
    // } else {
    // let selectedCountries = countryName && [countryName.toLowerCase()];
    if (countryName && selectedCountries.findIndex((items) => items === countryName) === -1) {
      selectedCountries.push(countryName);
    } else {
      selectedCountries = selectedCountries.filter((items) => items !== countryName);
      let removedCities = this.state.availableCities.filter(
        (items) => items.country.toLowerCase() === countryName.toLowerCase()
      );
      selectedCities = selectedCities.filter((items) => {
        return removedCities.findIndex((item) => item.city === items) === -1;
      });
    }
    this.setCities(selectedCountries);
    this.updateCards({ selectedCountries, selectedCities });
    // }
  };

  setCities = (countries) => {
    let selectedCity = this.state.availableCities.filter(
      (items) => countries && countries.indexOf(items.country.toLocaleLowerCase()) > -1
    );
    this.setState({ selectableCities: selectedCity });
  };

  handleOnFocusCitySearchInput = () => {
    if (this.state.citySearchInputValue.length) {
      this.search(this.state.citySearchInputValue);
    }
  };

  handleOnSearch = (e) => {
    const searchKeyword = e.target.value;

    this.setState({ citySearchInputValue: searchKeyword });

    this.search(searchKeyword);
  };

  search = (searchKeyword) => {
    if (searchKeyword.length === 0) {
      let selectedCard = this.state.cards[this.state.card];
      if (selectedCard) this.setCities(selectedCard.selectedCountries);
      this.setState({ showSearchResults: false });
      return;
    }

    this.setState({ showSearchResults: true });

    const result = [];
    // const regExp = new RegExp(searchKeyword, "i");
    this.state.selectableCities.map((city) => {
      const searchFrom = city.city.toLocaleLowerCase();

      if (searchFrom.includes(searchKeyword.toLocaleLowerCase())) {
        result.push(city);
      }
    });
    this.setState({ filteredCities: result });
  };

  handleOnSelectCity = (id) => {
    const city = this.state.selectableCities.find((c) => c.id === id);
    if (city) {
      let selectedCard = this.state.cards[this.state.card];
      let selectedCities = selectedCard.selectedCities ? [...selectedCard.selectedCities] : [];
      selectedCities.push(city.city.toLowerCase());
      let locationIds = [];
      if (Object.keys(selectedCard).indexOf("locationIds") > -1) {
        locationIds =
          typeof selectedCard.locationIds === "object" ? selectedCard.locationIds : [selectedCard.locationIds];
        locationIds.push(id);
      } else {
        locationIds = [id];
      }
      this.setState({ citySearchInputValue: "" });
      this.setState({ showSearchResults: false });
      this.updateCards({ selectedCities, locationIds });
    }
  };

  handleOnDismissCity = (name) => {
    let selectedCard = this.state.cards[this.state.card];
    let selectedCities = [...selectedCard.selectedCities];
    let locationIds =
      typeof selectedCard.locationIds === "object"
        ? selectedCard.locationIds.filter(
            (items) =>
              items !==
              this.state.availableCities.filter((item) => item.city.toLowerCase() === name.toLowerCase())[0].id
          )
        : [];

    selectedCities = selectedCities.filter((c) => {
      return c && c !== name;
    });

    this.updateCards({ selectedCities, locationIds });
  };

  handleOnSelectGender = (gender) => {
    this.updateCards({ selectedGender: gender });
  };
  // onChangeSwitch = (key, value) => {
  //   this.updateCards({ [key]: value });
  // };

  renderTopBar = () => {
    return <GoBackButton onClick={() => this.props.history.goBack()} />;
  };

  handleOnNavigateToNext = () => {
    this.setState({ citySearchInputValue: "" });
    const lengthOfCards = this.state.cards.length;

    if (lengthOfCards - 1 > this.state.card) {
      this.setState({ card: this.state.card + 1 });
    }
  };

  handleOnNavigateToPrev = () => {
    this.setState({ citySearchInputValue: "" });
    const lengthOfCards = this.state.cards.length;

    if (this.state.card - 1 >= 0) {
      this.setState({ card: this.state.card - 1 });
    }
  };

  updateCards = (card) => {
    let cards = [...this.state.cards];
    cards[this.state.card] = { ...cards[this.state.card], ...card };
    this.setState({ cards });
  };

  switchCardSide = () => {
    const card = this.state.cards[this.state.card];
    // this.updateCards({ side: card.side === "front" ? "back" : "front" });
    this.setState({
      cardSide: this.state.cardSide === "front" ? "back" : "front",
    });
  };

  handleReview = () => {
    if (this.validate()) {
      this.setState({ step: "review" });
    }
  };

  validate = () => {
    const selectedCard = this.state.cards[this.state.card];

    if (!selectedCard.allowedQuantity || selectedCard.allowedQuantity < 1) {
      this.showToast("error", "Error", "Allowed quantity is not valid");
      return false;
    } else if (!selectedCard.selectedCountries || selectedCard.selectedCountries.length < 1) {
      this.showToast("error", "Error", "please select at least one country ");
      return false;
    } else if (!selectedCard.selectedCities || selectedCard.selectedCities.length < 1) {
      this.showToast("error", "Error", "please select at least one city");
      return false;
    } else if (!selectedCard.selectedGender || selectedCard.selectedGender.length < 1) {
      this.showToast("error", "Error", "please select a gender");
      return false;
    } else {
      return true;
    }
  };

  showToast = (type, title, message) => {
    this.props.dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  submitCampaigns = async () => {
    this.setState({ showSubmitSpinner: true });
    const selectedCard = this.state.cards[this.state.card];
    let campaignData = {
      name: selectedCard.title,
      quantity: selectedCard.allowedQuantity,
      gender: selectedCard.selectedGender,

      expiresOn: selectedCard.expiry,
      inShuffle: selectedCard.inShuffle,
      inShop: selectedCard.inShop,
      locationIds: selectedCard.locationIds,
      cardId: selectedCard.id,
    };
    let campaignResp;
    if (this.state.campaignId) {
      // alert("update");
      campaignResp = await updateCampaignDetails(this.state.campaignId, campaignData);
      this.showToast("success", "Done", "Campaign updated");
      this.props.history.push("/dashboard");
    } else {
      campaignResp = await addCampaign(campaignData);

      if (campaignResp.data) {
        this.showToast("success", "Done", "Campaign added");
      }

      this.props.history.push("/dashboard");
    }

    this.setState({ showSubmitSpinner: false });
  };
  render() {
    const { cards, card, showing, active, cities, show, showLoading } = this.state;
    // console.log("card :>> ", card);
    const selectedCard = cards[card];
    const disabled = selectedCard?.status !== "active";
    if (showLoading)
      return (
        <Base theme={"light"}>
          <h1 style={{ marginLeft: "20px" }}>Loading...</h1>
        </Base>
      );
    if (cards.length === 0)
      return (
        <Base theme={"light"} topGap>
          {/* <h1 style={{ marginLeft: "20px", textAlign: "center" }}>No cards found</h1> */}

          <div className="no-offer-container">
            <Card>
              <div className="no-offer-card" style={{ padding: "20% 10%", textAlign: "center" }}>
                <h3 style={{ marginBottom: "40px" }}>Sorry, looks like you have not created an offer yet.</h3>
                <img src={blankCardImage} />
                <Button
                  style={{ marginTop: "40px", fontWeight: "bold", textTransform: "inherit" }}
                  onClick={() => this.props.history.push("/gift-card")}>
                  Create a new offer
                </Button>
              </div>
            </Card>
          </div>
        </Base>
      );

    if (this.state.paramsDecided !== null && selectedCard && show) {
      return (
        <Base theme={"light"} topBar={this.state.step === "create" ? this.renderTopBar : null}>
          {this.state.step === "create" && (
            <>
              <div className={"middle-content-area transparent create-new-campaign"}>
                {this.state.editTarget ? <p>Edit your targeting</p> : <p>Create a new campaign</p>}

                {this.renderCard()}
                <div className={"text-center"}>
                  <button className={"transparent-btn view-details-btn"} onClick={this.switchCardSide}>
                    Tap to view details
                  </button>
                </div>

                <Navigator
                  onNavigateToNext={this.handleOnNavigateToNext}
                  onNavigateToPrev={this.handleOnNavigateToPrev}
                />
                <Card>
                  <div className={"create-campaign-quantity-section"} style={{ marginBottom: "20px" }}>
                    <div className={"available-section"}>
                      <h3>Offer status:</h3>
                      <p style={{ textTransform: "capitalize" }}>{selectedCard.status.split("-").join(" ")}</p>
                    </div>
                  </div>
                </Card>

                <Card>
                  <div className={"create-campaign-quantity-section"}>
                    <div className={"available-section"}>
                      <h3>Gift cards available:</h3>
                      <p>{selectedCard.quantity}</p>
                    </div>
                    <div className={"set-quantity-section"}>
                      <div>
                        <p>
                          <h3>Set quantity:</h3> Max ({selectedCard.quantity})
                        </p>
                      </div>
                      <input
                        disabled={disabled}
                        type={"number pl-3"}
                        className={"transparent-input"}
                        value={selectedCard.allowedQuantity || 0}
                        onChange={this.handleOnChangeQuantity}
                      />
                    </div>
                    <div className={"set-quantity-controls"}>
                      <button onClick={this.incrementQuantity} disabled={disabled}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                      <button onClick={this.decrementQuantity} disabled={disabled}>
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                  </div>
                </Card>

                <h2>Set your location targeting</h2>

                <Card>
                  <div className={"select-available-country"}>
                    <h3>Select from available countries. </h3>

                    {this.state.availableCountries.map((countryName) => (
                      <CountryBadge
                        name={countryName}
                        selected={selectedCard?.selectedCountries?.includes(countryName)}
                        onClick={() => !disabled && this.toggleCountryName(countryName)}
                      />
                    ))}
                  </div>
                </Card>

                <Card>
                  <div className={"select-available-city"}>
                    <h3>Select from available cities.</h3>
                    {/* {this.state.selectableCities.map(({ city, id }) => (
                      <CountryBadge
                        name={city}
                        selected={selectedCard?.selectedCities?.includes(city.toLowerCase())}
                        onClick={() => !disabled && this.handleOnSelectCity(id)}
                      />
                    ))}
                    {console.log("citySearchInputValue :>> ", this.state.citySearchResults)} */}
                    <input
                      className={"search-city-input"}
                      type={"text"}
                      value={this.state.citySearchInputValue}
                      placeholder={"Search by city name"}
                      onChange={this.handleOnSearch}
                      onFocus={this.handleOnFocusCitySearchInput}
                    />
                    <div style={{ position: "relative" }}>
                      {this.state.showSearchResults && (
                        <CitiesSearchList results={this.state.filteredCities} onSelect={this.handleOnSelectCity} />
                      )}
                    </div>

                    <div className={"selected-cities-container"}>
                      {selectedCard?.selectedCities?.map((city) => {
                        return <CityItem name={city} onDismiss={this.handleOnDismissCity} />;
                      })}
                    </div>
                  </div>
                </Card>

                <Card>
                  <div className={"select-gender"}>
                    <h3>Select from available genders </h3>
                    <GenderRadioInput
                      disabled={disabled}
                      genders={this.state.genders}
                      selectedGender={selectedCard.selectedGender}
                      onselect={this.handleOnSelectGender}
                    />
                  </div>
                </Card>

                <div className={"bottom-button"}>
                  <GradiantButton disabled={disabled} onClick={() => this.handleReview()}>
                    Complete
                  </GradiantButton>
                </div>
              </div>
            </>
          )}
          {this.state.step === "review" && (
            <div className={"middle-content-area"}>
              <div className={"middle-content-area white preview-campaign"}>
                <p>Preview and Confirm</p>

                {this.renderCard()}

                <Card>
                  <div className={"preview-quantity-section"}>
                    <h3 className={"preview-quantity-text"}>
                      {"Quantity: "}
                      {selectedCard.allowedQuantity}
                    </h3>
                    <h3 className={"preview-location-text"} style={{ textTransform: "capitalize" }}>
                      {"Locations: "}
                      {selectedCard?.selectedCities?.length &&
                        selectedCard?.selectedCities
                          ?.map((items) => {
                            let selectedCity = this.state.availableCities.filter(
                              (item) => item.city.toLowerCase() === items.toLowerCase()
                            );
                            return `${selectedCity[0].city} -${selectedCity[0].country}`;
                          })
                          .join(",")}
                      {/* {selectedCard?.selectedCities?.length
                        ? `${selectedCard.selectedCities[0]}, ${selectedCard.selectedCountries[0]}.`
                        : ""} */}
                    </h3>
                    <h3 className={"preview-gender-text"}>
                      {"Gender: "}
                      {selectedCard.selectedGender}
                    </h3>

                    <div className={"complete-button-container"}>
                      <GradiantButton onClick={() => this.submitCampaigns()}>
                        {this.state.showSubmitSpinner ? <CircularProgress size={25} /> : "Complete"}
                      </GradiantButton>
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"go-back-button-container"}>
                <GoBackButton onClick={() => this.setState({ step: "create" })} />
              </div>
            </div>
          )}
        </Base>
      );
    } else {
      return (
        <Base theme={"light"}>
          <h1 style={{ marginLeft: "20px" }}>Loading...</h1>
        </Base>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserCards: () => dispatch(getUserCards()),
    dispatch: dispatch,
  };
}

function mapStateToProps(state) {
  return {
    standardUserCards: state.dropCards.standardUserCards,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardGift);
