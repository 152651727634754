import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// Layout for this app
import Login from "./views/Login/index";
import SignUp from "./views/SignUp/index";
import Forgotpassword from "./views/ForgotPassword/index";
import Passwordrecovery from "./views/PasswordRecovery/index";
import Home from "./views/Home/index";
import GiftCard from "./views/GiftCard/index";
import DropCard from "./views/DropCard/index";
import StandardGift from "./views/DropCard/StandardGift/StandardGift";
import GoldGift from "./views/DropCard/GoldGift/GoldGift";
import Campaigns from "./views/Campaigns/index";
import PendingApprovals from "./views/PendingApprovals/index";
import Settings from "./views/Settings/index";
import Logout from "./views/Logout/Logout";
import Notifications from "./component/Toast";
import ProtectedRoute from "./protected.Route";
import { connect } from "react-redux";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./App.scss";
import NoOffer from "./views/DropCard/StandardGift/NoOffer";
import EditOffer from "./views/EditOffer/index";
import { checkLoginAction } from "./redux/actions/authActions";
import history from "./helpers/history";
import { store } from "./redux/redusers/rootReducer";
import locationList from "./views/Locations/locationList";
import Transactions from "./views/Transactions";
import LocationFrom from "./views/Locations/locationForm";
import TiersList from "./views/Tiers/tiersList";
import TiersForm from "./views/Tiers/tiersForm";
import VerifyEmail from "./views/VerifyEmail";

class App extends React.Component {
  state = {
    canAccess: true,
  };
  componentWillMount() {
    let allowedUrl = ["/", "/signup", "/recovery-password", "/forgot-password", "/verify-email"];
    if (localStorage.getItem("access-token")) {
      store.dispatch(checkLoginAction());
    } else if (!allowedUrl.includes(window.location.pathname)) {
      window.location.href = "/";
    }
  }
  render() {
    return (
      <div className="main">
        <Notifications />
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/recovery-password" component={Passwordrecovery} />
            <Route path="/forgot-password" component={Forgotpassword} />
            <Route path="/logout" component={Logout} />
            <Route exact path="/locations" component={locationList} />
            <Route exact path="/locations/add" component={LocationFrom} />
            <Route exact path="/locations/edit/:id" component={LocationFrom} />
            <Route exact path="/tiers" component={TiersList} />
            <Route exact path="/tiers/add" component={TiersForm} />
            <Route exact path="/tiers/edit/:id" component={TiersForm} />
            <Route exact path="/profile-settings/:pricing?" component={Settings} />
            <Route exact path="/approvals" component={PendingApprovals} />
            <Route exact path="/campaigns" component={Campaigns} />
            <Route exact path="/drop-card/gold-gift" component={GoldGift} />
            <Route exact path="/drop-card/no-offer" component={NoOffer} />
            <Route exact path="/invoices" component={Transactions} />
            <Route exact path="/verify-email/:key?" component={VerifyEmail} />

            <Route exact path="/drop-card/standard-gift" component={StandardGift} />
            <Route exact path="/drop-card" component={DropCard} />
            <Route exact path="/campaigns/edit-offer-details/:cardId" component={EditOffer} />
            <Route exact path="/gift-card" component={GiftCard} />
            <Route exact path="/dashboard" component={Home} />
          </Switch>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login && state.login.token,
  };
}

export default connect(mapStateToProps, null)(App);
