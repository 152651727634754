import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import validator from "validator";
import Footer from "../GiftCard/DesignOffer/Footer";
import GiftCard from "../GiftCard/DesignOffer/GiftCard";
import Hint from "../GiftCard/DesignOffer/Hint";
import OfferInput from "../GiftCard/DesignOffer/Inputs/OfferInput";
import DiscountCodeInput from "../GiftCard/DesignOffer/Inputs/DiscountCodeInput";
import PromoteLinkInput from "../GiftCard/DesignOffer/Inputs/PromoteLinkInput";
import QuantityInput from "../GiftCard/DesignOffer/Inputs/QuantityInput";
import Base from "../../component/Layout/Base";
import history from "../../helpers/history";
import { useParams } from "react-router-dom";
import {
  fetchCardDetails,
  updateCardDetails,
  setFrontCardImage,
  setBackCardImage,
} from "../../redux/requests/cardRequests";
import { TOAST } from "../../redux/actions/actions-types";
import { useDispatch, useSelector } from "react-redux";
import htmlToImage from "html-to-image";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    backgroundColor: "#EAEAEA",
    paddingTop: 15,
    paddingBottom: 25,
  },
}));

const EditTarget = ({}) => {
  const classes = useStyles();
  const params = useParams();
  const cardId = params.cardId;
  const [card, setCard] = useState();
  const [logoPhoto, setCardLogo] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const companyLogo = useSelector((state) => state.login.userData.companyLogo);

  useEffect(() => {
    if (cardId) {
      getCardDetails();
    }
  }, [cardId]);
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  const getCardDetails = async () => {
    let cardResp = await fetchCardDetails(cardId);
    if (cardResp && cardResp.data) {
      cardResp.data.cardPhoto = cardResp.data.cardPhoto.includes(window.location.protocol)
        ? cardResp.data.cardPhoto
        : `${process.env.REACT_APP_BASE_URL}/uploads/card-photo/${cardResp.data.cardPhoto}`;
      console.log("Testing", cardResp.data);
      if (cardResp.data.logoPhoto) setCardLogo(`${cardResp.data.logoPhoto}`);
      setCard(cardResp.data);
    }
  };
  const getThumbnail = async () => {
    var nodeFront = document.getElementById("card-front");
    var nodeBack = document.getElementById("card-back");

    let thumbnailFront = await htmlToImage.toPng(nodeFront);
    let thumbnailBack = await htmlToImage.toPng(nodeBack);
    // let img = new Image();
    // img.src = thumbnailFront;
    // document.body.appendChild(img);
    return { thumbnailFront, thumbnailBack };
  };
  const [cardSide, setCardSide] = useState("front");
  const handleOnClickAttachLogo = () => {
    if (companyLogo) {
      setCardLogo(`${companyLogo}`);
      handleOnChangeInput("logoPhoto", `${companyLogo}`);
    } else {
      showToast("error", "Error", "Select company logo from settings");
    }
  };
  const handleOnChangeInput = (key, value) => {
    const cardCopy = { ...card };
    cardCopy[key] = value;
    setCard(cardCopy);
  };
  const showError = (message) => {
    dispatch({
      type: TOAST,
      load: {
        type: "error",
        title: "Error",
        message: message,
        show: true,
      },
    });
  };
  const validation = () => {
    if (!validator.isLength(card.details, { min: 3 })) {
      showError("Add details");
      return false;
    } else if (!card.cardValue) {
      showError("Select offer value");
      return false;
    } else if (card.cardValue <= 0) {
      showError("Invalid offer value");
      return false;
    } else if (!card.quantity) {
      showError("Select Quantity");
      return false;
    } else if (card.quantity <= 0) {
      showError("Invalid Quantity");
      return false;
    }
    if (!validator.isURL(card.promoteUrl)) {
      showError("Invalid promo url");
      return false;
    } else if (!validator.isLength(card.code, { min: 3 })) {
      showError("Discount code must contain three characters");
      return false;
    }
    return true;
  };
  const handleOnClickFooterButton = async (button) => {
    if (button === "back") {
      history.goBack();
    } else if (button === "save") {
      const valid = validation();
      if (valid) {
        setShowLoader(true);
        let thumbnail = await getThumbnail();
        // return;
        let frontFormData = new FormData();
        frontFormData.append("card-photo", thumbnail.thumbnailFront);
        let backFormData = new FormData();

        if (!card.logoPhoto) delete card.logoPhoto;

        backFormData.append("card-photo", thumbnail.thumbnailBack);
        let updateResp = await updateCardDetails(cardId, card);
        let cardPhotoFront = await setFrontCardImage(cardId, frontFormData);
        let cardPhotoBack = await setBackCardImage(cardId, backFormData);
        showToast("success", "Done", "Card updated");
        history.goBack();
        setShowLoader(false);
      }
    }
  };
  const handleOnRemoveLogo = () => {
    setCardLogo();
    handleOnChangeInput("logoPhoto", "");
  };

  const toggleCardSide = () => {
    setCardSide(cardSide === "front" ? "back" : "front");
  };

  if (card) {
    return (
      <Base theme={"light"} topGap>
        <div style={{ opacity: 0, position: "absolute" }}>
          <div id="card-front" style={{ position: "absolute" }}>
            <GiftCard
              side="front"
              rounded
              shadow
              {...card}
              logoPhoto={
                logoPhoto && logoPhoto.includes(window.location.protocol)
                  ? logoPhoto
                  : `${process.env.REACT_APP_BASE_URL}/uploads/avatars/${logoPhoto}`
              }
              onUploadLogo={handleOnClickAttachLogo}
              onRemoveLogo={handleOnRemoveLogo}
              onChangeTitle={(e) => {
                handleOnChangeInput("title", e.target.value);
              }}
              onChangeDescription={(e) => handleOnChangeInput("details", e.target.value)}
              step={3}
              view={true}
            />
          </div>
          <div id="card-back" style={{ position: "absolute" }}>
            <GiftCard
              side="back"
              rounded
              shadow
              {...card}
              logoPhoto={
                logoPhoto && logoPhoto.includes(window.location.protocol)
                  ? logoPhoto
                  : `${process.env.REACT_APP_BASE_URL}/uploads/avatars/${logoPhoto}`
              }
              onUploadLogo={handleOnClickAttachLogo}
              onRemoveLogo={handleOnRemoveLogo}
              onChangeTitle={(e) => {
                handleOnChangeInput("title", e.target.value);
              }}
              onChangeDescription={(e) => handleOnChangeInput("details", e.target.value)}
              step={3}
            />
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <div className={"card-offer-container"}>
              <div className="card-offer-content">
                <div className={"card-offer-header"}>
                  <p>Edit your offer</p>
                </div>
                <div className={"card-section review"}>
                  <GiftCard
                    side={cardSide}
                    rounded
                    shadow
                    {...card}
                    logoPhoto={
                      logoPhoto && logoPhoto.includes(window.location.protocol)
                        ? logoPhoto
                        : `${process.env.REACT_APP_BASE_URL}/uploads/avatars/${logoPhoto}`
                    }
                    onUploadLogo={handleOnClickAttachLogo}
                    onRemoveLogo={handleOnRemoveLogo}
                    onChangeTitle={(e) => {
                      handleOnChangeInput("title", e.target.value);
                    }}
                    onChangeDescription={(e) => handleOnChangeInput("details", e.target.value)}
                    step={3}
                  />
                  <p>
                    <button onClick={toggleCardSide}>View {cardSide === "front" ? "back" : "front"} of card</button>
                  </p>
                  <p className={"short-text"}>Edit title and details directly on card</p>
                </div>
                <div
                  className={`bottom-section card-back-options-container-1 ${classes.bottomSection} edit-offer-details`}>
                  <Hint>Select the amount of available offers to be collected</Hint>
                  <QuantityInput
                    minValue={1}
                    maxValue={1000000}
                    value={card.quantity}
                    onChange={(quantity) => handleOnChangeInput("quantity", quantity)}
                  />
                  <Hint>What is the approximate monetary value of your offer?</Hint>
                  <OfferInput
                    minValue={1}
                    maxValue={1000000}
                    value={card.cardValue}
                    onChange={(cardValue) => handleOnChangeInput("cardValue", cardValue)}
                  />
                  <Hint>Discount Code</Hint>
                  <DiscountCodeInput
                    value={card.code}
                    onChange={(e) => handleOnChangeInput("code", e.target.value)}
                    editForm
                    hideLable
                  />
                  <Hint>Promote url link</Hint>
                  <PromoteLinkInput
                    value={card.promoteUrl}
                    onChange={(e) => handleOnChangeInput("promoteUrl", e.target.value)}
                    editForm
                    hideLabel
                  />
                </div>
              </div>
            </div>
          </div>

          <Footer
            buttons={[
              {
                title: "Back",
                tabIndex: "back",
              },
              {
                title: "Save",
                tabIndex: "save",
                showLoader,
              },
            ]}
            onClickButton={handleOnClickFooterButton}
          />
        </div>
      </Base>
    );
  } else {
    return (
      <Base theme={"light"}>
        <h1 style={{ marginLeft: "20px" }}>Loading...</h1>;
      </Base>
    );
  }
};

export default EditTarget;
