import React, { useState, useEffect } from "react";

import {
  withStyles,
  makeStyles,
  Table,
  Paper,
  Grid,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  CircularProgress,
  Input,
} from "@material-ui/core";

import { dateFormat } from "../../../helpers/dateFormat";
import { callPendingCards } from "../../../redux/actions/approvalsActions";

import ApprovalForm from "./ApprovalForm1";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchPendingCampaign, updateCampaign } from "../../../redux/requests/pendingApprovalRequest";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TOAST } from "../../../redux/actions/actions-types";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableheadcell: {
    backgroundColor: "#1C1524",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23px",
  },
  tablebodycell: {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "27px",
    textTransform: "capitalize",
  },
  backwrapper: {
    display: "flex",
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1rem",
    position: "sticky",
    left: "0",
    top: "0",
  },
  textTypo: {
    color: "#fff",
    marginTop: "0 !important",
    marginBottom: "20px !important",
    marginLeft: "1rem",
  },
});

const CssTextField = withStyles({
  root: {
    "& .MuiInput-input": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
})(TextField);

function Approvals(props) {
  const classes = useStyles();
  const { pendingCards } = props;
  // const [open, setOpen] = useState(false);
  const [cardIdx, setIdx] = useState();

  const toggleShow = (idx) => {
    setOpen(true);
    setIdx(idx);
  };

  const toggleHide = () => {
    setOpen(false);
  };
  const [cards, setCards] = useState();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [cardId, setCardId] = useState();
  const [showApproveSpinner, setShowApproveSpinner] = useState();
  const [showRejectSpinner, setShowRejectSpinner] = useState(false);
  const [rejectText, setRejectText] = useState("");
  const dispatch = useDispatch();
  const role = useSelector((state) => state.login.userData.role);
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    let cardData = await fetchPendingCampaign(role);
    if (cardData && cardData.data) {
      setCards(cardData.data);
    }
  };

  const getExpiry = (date) => {
    let dateDiff = moment(date).diff(moment());
    return dateDiff < 0
      ? `Expired : ${moment(date).format("MMMM D YYYY")}`
      : `Expires on : ${moment(date).format("MMMM D YYYY")}`;
  };

  const approve = async (id) => {
    let data = {
      status: "active",
      isApproved: true,
    };
    setShowApproveSpinner(id);
    let updateResp = await updateCampaign(id, data);
    if (updateResp.name !== "Error") {
      showToast("success", "Done", "Approved!");
    }
    getTemplates();
    setShowApproveSpinner();
  };
  const reject = (id) => {
    setCardId(id);
    setOpen(true);
  };
  const submitReject = async () => {
    let rejectReason = rejectText && rejectText.trim();
    if (!rejectReason || rejectReason.length < 1) {
      return showToast("error", "Error", "You should specify a rejection reject.");
    }
    let data = {
      status: "pending",
      isApproved: false,
      rejectionReason: rejectReason,
    };
    setShowRejectSpinner(true);
    let updateResp = await updateCampaign(cardId, data);

    if (updateResp.name !== "Error") {
      showToast("success", "Done", "Rejected");
      getTemplates();
      setOpen(false);
    }
    setShowRejectSpinner(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const reducer = () => {
    let totalQuantity = 0;
    if (!Array.isArray(cards)) return totalQuantity;
    for (let card of cards) {
      totalQuantity = totalQuantity + card.quantity;
    }
    return totalQuantity;
  };
  const searchFor = async () => {
    let res = await fetchPendingCampaign(role, search);
    if (res && res.data && Array.isArray(res.data)) {
      if (res.data.length === 0) {
        showToast("error", "Alert", "No match found");
      }
      setCards(res.data);
    }
  };
  return (
    <div>
      <div className="card-container">
        <div>
          <Input
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onKeyPress={(e) => (e.key === "Enter" ? searchFor() : "")}
            inputProps={{ style: { color: "black" } }}
          />
          <Button onClick={searchFor}>Search</Button>
        </div>
        <div className={classes.backwrapper}>
          <h3 align="left" className={classes.textTypo}>
            Que
          </h3>
          <h3 align="left" className={classes.textTypo}>
            Total:
            <span className="float-right">
              <span> {cards?.length} Templates</span>&nbsp;&nbsp;
            </span>
          </h3>
          <h3 align="left" className={classes.textTypo}>
            <span>Quantity: {reducer()} </span>
          </h3>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              {cards?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className={classes.tablebodycell}>{row.name}</StyledTableCell>

                  <StyledTableCell className={classes.tablebodycell}>Quantity: {row.quantity}</StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>{row.status.split("-").join(" ")}</StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>{getExpiry(row.expiresOn)}</StyledTableCell>
                  <StyledTableCell className={classes.tablebodycell}>
                    {role === "admin" && row.status.includes("pending") && (
                      <>
                        <button className="go-back-button text-success" onClick={() => approve(row.id)}>
                          {showApproveSpinner === row.id ? <CircularProgress /> : "Approve"}
                        </button>
                        <button className="go-back-button text-danger" onClick={() => reject(row.id)}>
                          Reject
                        </button>
                      </>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Reject</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Submit a reason why this template is being rejected. So that owner can review this and make changes
              accordingly.
            </DialogContentText>
            <CssTextField
              autoFocus
              margin="dense"
              id="reason"
              label="Rejection reason"
              onChange={(e) => setRejectText(e.target.value)}
              multiline
              InputProps={{
                className: classes.textDark,
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" onClick={() => submitReject()}>
              {showRejectSpinner ? <CircularProgress /> : "Reject"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    pendingCards: state.approvals.pendingCards,
    approveResult: state.approvals.approveResult,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callPendingCards: () => dispatch(callPendingCards()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Approvals);
