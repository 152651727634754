import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";

import ArrowForwardIcon from "./../../../assets/images/ArrowForwardIcon.svg";
import PersonalInfoInput from "./PersonalInfoInput";
import Card from "../../../component/Layout/Card";
import PricingButton from "./PricingButton";
import Plans from "../Plans/Plans";
import Modal from "@material-ui/core/Modal";
import { TOAST } from "../../../redux/actions/actions-types";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData, updateCompanyAvatar } from "../../../redux/requests/authRequests";
import { checkLoginAction } from "../../../redux/actions/authActions";
import { getPaidInvoices } from "../../../redux/requests/paymentRequest";
import validator from "validator";
import Payment from "../Payment/index";
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    textAlign: "center",
    width: "100%",
    marginBottom: "100px",
  },
  addform: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    maxWidth: "430px",
    margin: "0 auto",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  paymentContainer: {
    marginBottom: "1rem",
  },
  avatarImage: {
    // position: "absolute !important",
    borderRadius: "50%",
    height: "180px",
    width: "180px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    transition: "0.5s",
    borderRadius: "50%",
    height: "180px",
    width: "180px",
    left: 0,
    top: 0,
    position: "absolute",
    "&:hover": {
      opacity: ".5",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ViewPersonalInfo(props) {
  const email = localStorage.getItem("email");
  const [userLogo, setUserLogo] = useState();
  const company = localStorage.getItem("company");
  const [userAvatar, setUserAvatar] = useState();
  const userData = useSelector((state) => state.login.userData);
  const dispatch = useDispatch();
  const [paymentModal, setPaymentModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const togglePaymentModal = () => setPaymentModal(!paymentModal);
  const [open, setOpen] = React.useState(false);

  const [profile, setProfile] = useState({
    email: {
      value: "",
      editing: false,
    },
    password: {
      value: "",
      editing: false,
    },
    businessWebsite: {
      value: "",
      editing: false,
    },
    billing: {
      value: "$0.00 spent",
      editing: false,
      noButton: true,
    },
    billing_details: {
      value1: "Next billing 07/21",
      value2: "MC **** **** 5042",
    },
  });

  useEffect(() => {
    dispatch(checkLoginAction());
    getBillingInfo();
  }, []);

  const getBillingInfo = async () => {
    let billingResp = await getPaidInvoices();
    if (billingResp && billingResp.data) {
      let amount = 0;
      for (let bill of billingResp.data) {
        amount = amount + bill.amount;
      }
      setProfile({ ...profile, billing: { ...profile.billing, value: `$${parseFloat(amount).toFixed(2)} spent` } });
    }
  };
  useEffect(() => {
    if (props.showPricing) {
      setOpen(true);
    }
  }, [props.showPricing]);

  const toggleOpen = () => {
    if (props.showPricing) {
      window.history.replaceState({}, "", `${window.location.origin}/profile-settings`);
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (userData) {
      let profileData = { ...profile };
      for (let key of Object.keys(profileData)) {
        if (userData[key]) {
          profileData[key].value = userData[key];
        }
      }
      setUserLogo(userData.companyLogo);
      setProfile(profileData);
    }
  }, [userData]);
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  const classes = useStyles();
  const [logo, setLogo] = useState(userLogo);
  const logoSelectedHandler = (event) => {
    let img = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        let userLogo = reader.result;

        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const uid = localStorage.getItem("userId");
        localStorage.setItem("userLogo", userLogo);

        const logo = {
          user_id: uid,
          userLogo: userLogo,
        };

        setUserAvatar(userLogo);
        updateAvatar(img);
      },
      false
    );

    if (img) {
      reader.readAsDataURL(img);
    }
  };

  const updateAvatar = async (image) => {
    let formData = new FormData();
    formData.append("photo", image);
    let avatarResp = await updateCompanyAvatar(formData);
    if (avatarResp) {
      showToast("success", "Done", "Avatar updated");
      dispatch(checkLoginAction());
    }
  };

  const handleOnChange = (key, value) => {
    const field = { ...profile[key] };
    field.value = value;

    const profileCopy = { ...profile };
    profileCopy[key] = field;
    setProfile(profileCopy);
  };

  const onToggleButton = async (key) => {
    const field = { ...profile[key] };
    const profileCopy = { ...profile };

    let data = { [key]: profileCopy[key].value };
    if (field.editing) {
      console.log(data[key]);
      if (key === "email" && !validator.isEmail(data[key])) {
        showToast("error", "Error", "Invalid email");
      } else if (key === "password" && (!data[key] || !validator.isLength(data[key], { min: 6 }))) {
        showToast("error", "Error", "Invalid password");
      } else if (
        key === "businessWebsite" &&
        profile.businessWebsite.value &&
        (!data[key] || !validator.isURL(data[key]))
      ) {
        showToast("error", "Error", "Invalid bussiness website");
      } else {
        let updateResp = await updateUserData(data);
        if (updateResp.name !== "Error") {
          showToast("success", "Done", "Profile updated");
          dispatch(checkLoginAction());
          field.editing = !field.editing;
          profileCopy[key] = field;

          setProfile(profileCopy);
        }
      }
    } else {
      field.editing = !field.editing;
      profileCopy[key] = field;

      setProfile(profileCopy);
    }
  };

  return (
    <div className={`classes.container settings-container`}>
      <form name="form-layout" className={classes.addform} noValidate>
        <div className="avatar-action">
          <label>
            Upload Logo
            <input type="file" onChange={logoSelectedHandler} />
            {userAvatar ? (
              <img
                src={`${userAvatar}`}
                className={classes.avatarImage}
                // style={{

                // }}
                // alt="logo"
              />
            ) : (
              userLogo && (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/uploads/avatars/${userLogo}`}
                  className={classes.avatarImage}
                  // style={{
                  //   transition: "0.5s",
                  //   borderRadius: "50%",
                  //   height: "180px",
                  //   width: "180px",
                  //   position: "absolute",
                  // }}
                  // alt="logo"
                />
              )
            )}
          </label>
          <h3>Welcome to Run for stuff</h3>
        </div>

        {userData.role === "company" && (
          <>
            {" "}
            {userData.activePaymentDetail ? (
              <h1>Subscribed</h1>
            ) : (
              <PricingButton creditRemaining={userData?.creditsRemaining || 0} onClick={() => setOpen(true)} />
            )}{" "}
          </>
        )}

        {/* <PricingButton onClick={() => setOpen(true)} /> */}

        <Card>
          <PersonalInfoInput
            label={"Email"}
            value={profile.email.value}
            editing={profile.email.editing}
            buttonText={"Change email"}
            onChangeValue={(e) => handleOnChange("email", e.target.value)}
            onSave={() => onToggleButton("email")}
          />
        </Card>
        <Card>
          <PersonalInfoInput
            label={"Password"}
            value={profile.password.value}
            type={"password"}
            editing={profile.password.editing}
            placeholder="******"
            buttonText={"Change password"}
            onChangeValue={(e) => handleOnChange("password", e.target.value)}
            onSave={() => onToggleButton("password")}
          />
        </Card>
        <Card>
          <PersonalInfoInput
            label={"Business Website"}
            value={profile.businessWebsite.value}
            editing={profile.businessWebsite.editing}
            buttonText={"Edit"}
            onChangeValue={(e) => handleOnChange("businessWebsite", e.target.value)}
            onSave={() => onToggleButton("businessWebsite")}
          />
        </Card>

        {userData.activePaymentDetail && (
          <>
            <Card>
              <PersonalInfoInput
                label={"Billing"}
                value={profile.billing.value}
                editing={profile.billing.editing}
                buttonText={"Edit"}
                onChangeValue={(e) => handleOnChange("billing", e.target.value)}
                onSave={() => onToggleButton("billing")}
                noButton={profile.billing.noButton}
              />
            </Card>
            <Card>
              <PersonalInfoInput
                label={"Billing Details"}
                value1={userData.activePaymentDetail.brand}
                value2={`**** **** ${userData.activePaymentDetail.last4}`}
              />
            </Card>
            <Button variant="outlined" onClick={() => togglePaymentModal()}>
              Change payment method
            </Button>
          </>
        )}
        <>
          {userData?.role === "company" && (
            <div className={classes.paymentContainer}>
              <Modal open={paymentModal}>
                <div style={modalStyle} className={classes.paper}>
                  <h2 id="simple-modal-title">Add payment method</h2>
                  <p id="simple-modal-description">
                    <Payment
                      onSuccess={() => {
                        togglePaymentModal();
                        setOpen(false);
                        dispatch(checkLoginAction());
                      }}
                      onClose={() => togglePaymentModal()}
                    />
                  </p>
                </div>
              </Modal>
            </div>
          )}
        </>
      </form>

      <Modal open={open} style={{ overflowX: "auto" }}>
        <>
          <Plans
            onClose={() => toggleOpen()}
            backSettings={() => this.changeTab(0)}
            onPressSelect={togglePaymentModal}
          />
        </>
      </Modal>
    </div>
  );
}

export default ViewPersonalInfo;
