import * as types from './actions-types'

export const setUserData = (userCard) => ({
    type: types.ADD_CARD,
    userCard
})

export function addCardSuccess(userCard) {
    return {
        type: types.ADD_CARD_SUCCESS,
        userCard
    }
}