import React, { useState } from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { addPaymentMethod } from "../../../redux/requests/paymentRequest";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { TOAST } from "../../../redux/actions/actions-types";
import { useDispatch } from "react-redux";

function CheckoutFrom({ onSuccess, onClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const showToast = (type, title, message) => {
    dispatch({
      type: TOAST,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };
  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    setShowLoader(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      let paymentResp = await addPaymentMethod({ pmId: paymentMethod.id, card: paymentMethod.card });
      console.log(paymentResp);
      if (paymentResp.name !== "Error") {
        showToast("success", "Done", "Payment method saved");
        onSuccess();
      }
      //   console.log("[PaymentMethod]", JSON.stringify(paymentMethod));
    }
    setShowLoader(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />

        <Button type="submit" disabled={!stripe} style={{ marginTop: "2rem" }}>
          {showLoader ? <CircularProgress color={"primary"} size={30} /> : "Add"}
        </Button>

        <Button style={{ marginTop: "2rem" }} onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

export default CheckoutFrom;
