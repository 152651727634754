import * as types from '../actions/actions-types'

//saga
export const getUserCards = () => ({
    type: types.GET_DROP_CARDS_STANDARD
})

//reducer
export const dropUserCardsStandard = (userDropCards) => {
    return {
        type: types.DROP_USER_CARDS_STANDARD,
        userDropCards
    }
}
